import { FloatingLabel, Form, Tab } from "react-bootstrap";
import { ICS2CreateTournamentViewModel } from "../../viewModels/tournament/CS2CreateTournamentViewModel";

interface Cs2SettingsFormProps {
    cs2Tournament: ICS2CreateTournamentViewModel;
    handleCS2TournamentVMChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    showCompetitive: boolean;
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    filteredMaps: Array<{ mapId: string; mapImgUrl: string; name: string }>;
    selectedMaps: string[];
    handleMapClick: (mapId: string) => void;
}

const CS2TabCreateTournament: React.FC<Cs2SettingsFormProps> = ({ cs2Tournament,
    handleCS2TournamentVMChange,
    showCompetitive,
    handleCheckboxChange,
    filteredMaps,
    selectedMaps,
    handleMapClick }) => {
    return (
        <Tab.Pane eventKey="fourth">
            <Form className="create_tournament_form">
                <FloatingLabel label="КОЛИЧЕСТВО ИГРОВЫХ ПАУЗ НА МАТЧ" className='create_tourn_label'>
                    <Form.Select
                        className="create_tournament_form_control option"
                        name="pauseQuantity"
                        value={cs2Tournament.pauseQuantity || ''}
                        required
                        onChange={handleCS2TournamentVMChange}
                    >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel label="ПРОДОЛЖИТЕЛЬНОСТЬ ИГРОВЫХ ПАУЗ (сек.)" className='create_tourn_label'>
                    <Form.Select
                        className="create_tournament_form_control option"
                        name="pauseDuration"
                        value={cs2Tournament.pauseDuration || ''}
                        required
                        onChange={handleCS2TournamentVMChange}
                    >
                        <option value={30}>30</option>
                        <option value={60}>60</option>
                        <option value={90}>90</option>
                        <option value={120}>120</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel label="ВРЕМЯ НА ВЫБОР КАРТ (сек.)" className='create_tourn_label'>
                    <Form.Select
                        className="create_tournament_form_control option"
                        name="mapBanSeconds"
                        onChange={handleCS2TournamentVMChange}
                        value={cs2Tournament.mapBanSeconds || ''}
                    >
                        <option value={15}>15</option>
                        <option value={30}>30</option>
                        <option value={45}>45</option>
                        <option value={60}>60</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel label="FREEZETIME (сек.)" className='create_tourn_label'>
                    <Form.Select
                        className="create_tournament_form_control option"
                        name="freezeTime"
                        onChange={handleCS2TournamentVMChange}
                        value={cs2Tournament.freezeTime || ''}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                    </Form.Select>
                </FloatingLabel>
                <div className='сs2_mapselection_container'>
                    <div className='сs2_mapselection_container_header'>ВЫБОР КАРТ ТУРНИРА</div>
                    <Form.Check
                        className="create_tournament_checkbox"
                        type="checkbox"
                        checked={showCompetitive}
                        onChange={handleCheckboxChange}
                        label="Выбрать только соревновательные карты"
                    />
                    <div className='сs2_mapselection_maps'>
                        {filteredMaps.map((map) => (
                            <div
                                key={map.mapId}
                                style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_MINIO_URL}${map.mapImgUrl})` }}
                                onClick={() => handleMapClick(map.mapId)}
                                className={selectedMaps.includes(map.mapId) ? 'selected' : ''}>
                                <p className='cs2_mapselection_maps_title'>{map.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </Form>
        </Tab.Pane>
    );
};

export default CS2TabCreateTournament;