import React, { useEffect, useState } from 'react';
import './TournamentManage.css';
import { ICommonTournamentModel } from '../../../../../models/dto/tournament/ICommonTournamentModel';
import { getTournamentsByFilters } from '../../../../../http/tournament/tournamentActionsAPI';
import { Button, Form, Pagination } from 'react-bootstrap';
import { TournamentConstants } from '../../../../../helpers/constants/tournamentConstants';
import CustomSpinner1 from '../../../../../components/CustomSpinners/CustomSpinner1';
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import { approveTournament } from '../../../../../http/adminActionsAPI';
import { TournamentSF } from '../../../../../viewModels/filters/TournamentSF';
import { useNavigate } from 'react-router-dom';

const TournamentManage: React.FC = () => {
    const [statusFilter, setStatusFilter] = useState('active');
    const navigate = useNavigate();
    const [fetchingTournamentsList, setFetchingTournamentsList] = useState(true);
    const [tournamentsList, setTournamentsList] = useState<ICommonTournamentModel[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const tournamentsPerPage = 6;
    const [gameTypeIdsSelected, setGameTypeIdsSelected] = useState<string[]>([TournamentConstants.CS2.gameTypeId]);
    const [specialFilter, setSpecialFilter] = useState<string>('needsApproval');

    const [filters, setFilters] = useState<TournamentSF>({
        gameTypeIds: gameTypeIdsSelected,
        canRegistrate: null,
        approvedByAdmin: false,
        approvedByOrganisator: null,
        ///TODO: statusIds когда будет норм логика со статусами
        page: 1,
        limit: tournamentsPerPage
    });

    const updateFilter = (filterName: string, value: boolean | null) => {
        setFilters(prevFilters => {
            const updatedFilters = {
                ...prevFilters,
                canRegistrate: filterName === 'canRegistrate' ? value : null,
                approvedByAdmin: filterName === 'approvedByAdmin' ? value : filterName === 'needsApproval' ? false : null,
                approvedByOrganisator: filterName === 'approvedByOrganisator' ? value : null,
            };
            return updatedFilters;
        });
    };

    useEffect(() => {
        updateFilter(specialFilter, specialFilter ? true : null);
        setFilters(prevFilters => ({
            ...prevFilters,
            gameTypeIds: gameTypeIdsSelected,
            page: currentPage,
        }));
    }, [gameTypeIdsSelected, currentPage, specialFilter]);

    useEffect(() => {
        setFetchingTournamentsList(true);
        getTournamentsByFilters(filters)
            .then(data => {
                if (data) {
                    setTournamentsList(data.tournaments);
                    const totalCount = data.totalCount;
                    const calculatedTotalPages = Math.ceil(totalCount / filters.limit);
                    setTotalPages(calculatedTotalPages > 0 ? calculatedTotalPages : 1);
                }
            })
            .finally(() => setFetchingTournamentsList(false));
    }, [filters]);

    // const activeTournaments = tournamentsList.filter(tournament => {
    //     const endDate = tournament.endDate ? new Date(tournament.endDate) : null;
    //     return endDate && endDate > today;
    // });

    // const pastTournaments = tournamentsList.filter(tournament => {
    //     const endDate = tournament.endDate ? new Date(tournament.endDate) : null;
    //     return endDate && endDate <= today;
    // });

    const handleGameTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setGameTypeIdsSelected([event.target.value]);
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setStatusFilter(event.target.value);
    };

    const handleSpecialFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSpecialFilter(event.target.value);
    };

    // const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    //     const { name, value } = event.target;
    //     setFilters(prevFilters => ({
    //         ...prevFilters,
    //         [name]: value
    //     }));
    // };

    const handleApprove = (tournamentId: string) => {
        setFetchingTournamentsList(true);
        approveTournament(tournamentId).then(() => {
            getTournamentsByFilters(filters)
                .then(data => {
                    if (data) {
                        const tournament = data.tournaments;
                        const totalCount = data.totalCount;
                        setTournamentsList(tournament);
                        const calculatedTotalPages = Math.ceil(totalCount / filters.limit);
                        setTotalPages(calculatedTotalPages > 0 ? calculatedTotalPages : 1);
                    }
                })
                .finally(() => setFetchingTournamentsList(false));
        });
    };

    const formatDateRange = (startDate: string, endDate: string): string => {
        const start = new Date(startDate).toLocaleDateString();
        const end = new Date(endDate).toLocaleDateString();
        return `${start} - ${end}`;
    };

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const renderPaginationItems = () => {
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => paginate(number)}
                >
                    {number}
                </Pagination.Item>,
            );
        }
        return items;
    };

    return (
        <div className='tournaments_manage_tab_container'>
            <h4>Управление турнирами</h4>
            <Button onClick={() => { window.open('/create-tournament', '_blank') }}>Создать турнир</Button>
            <div className='current_tournaments_unit'>
                <div className='current_tournaments_unit_table'>
                    <div className='all_tourn_header'>Все турниры</div>
                    <div className='tournaments_manage_filter_container'>
                        {/* <Form.Select
                            name="status"
                            value={statusFilter}
                            required
                            onChange={handleStatusFilterChange}
                        >
                            <option value="active">Активные</option>
                            <option value="past">Прошедшие</option>
                        </Form.Select> */}
                        <Form.Select
                            className="tournaments_manage_filter"
                            name="specialFilter"
                            value={specialFilter}
                            onChange={handleSpecialFilterChange}
                        >
                            <option value="needsApproval">Ожидают одобрения</option>
                            <option value="canRegistrate">Идет регистрация</option>
                            <option value="approvedByAdmin">Одобрен Админом</option>
                            <option value="approvedByOrganisator">Одобрен Организатором</option>
                        </Form.Select>
                        <Form.Select
                            className="tournaments_manage_filter"
                            name="gameTypeIdsSelected"
                            value={gameTypeIdsSelected}
                            required
                            onChange={handleGameTypeChange}
                        >
                            <option value={TournamentConstants.CS2.gameTypeId}>CS2</option>
                            <option value={TournamentConstants.DOTA2.gameTypeId}>DOTA2</option>
                            <option value={TournamentConstants.FORTNITE.gameTypeId}>Fortnite</option>
                        </Form.Select>
                    </div>
                    {fetchingTournamentsList ? (
                        <CustomSpinner1 />
                    ) : (
                        <table className='current_tournaments_table'>
                            <thead>
                                <tr>
                                    <th className='current_tournaments_table_header name'>Название</th>
                                    <th className='current_tournaments_table_header'>Даты</th>
                                    <th className='current_tournaments_table_header'>Свободно мест</th>
                                    <th className='current_tournaments_table_header'>Статус</th>
                                    <th className='current_tournaments_table_header'>Одобрен организатором</th>
                                    <th className='current_tournaments_table_header'>Одобрен Админом</th>
                                    <th className='current_tournaments_table_header'>Редактировать</th>
                                    <th className='current_tournaments_table_header'>Удалить</th>
                                </tr>
                            </thead>
                            <tbody className='current_tournaments_table_body'>
                                {tournamentsList.map(tournament => (
                                    <tr key={tournament.gameTypeId}>
                                        <td className='current_tournaments_table_td name' onClick={() => { window.open(`/tournament/${tournament.tournamentId}`, '_blank') }}>
                                            {tournament.name}
                                        </td>
                                        <td className='current_tournaments_table_td'>{formatDateRange(tournament.startDate, tournament.endDate)}</td>
                                        <td className='current_tournaments_table_td'>{tournament.maxSlots - tournament.activeSlots}</td>
                                        <td className='current_tournaments_table_td'>{tournament.statusName}</td>
                                        <td className={`current_tournaments_table_td ${tournament.approvedByOrganisator ? 'approved' : 'not-approved'}`}>
                                            {tournament.approvedByOrganisator ? 'Одобрен' : 'Не одобрен'}
                                        </td>
                                        <td className={`current_tournaments_table_td ${tournament.approvedByAdmin ? 'approved' : 'not-approved'}`}>
                                            {tournament.approvedByAdmin ? 'Одобрен' :
                                                <Button className='current_tournaments_table_button' onClick={() => handleApprove(tournament.tournamentId)}>Одобрить</Button>
                                            }
                                        </td>
                                        <td className='current_tournaments_table_td' onClick={() => navigate(`/update-tournament/${tournament.tournamentId}`)}>
                                            <PencilFill className='general_icon' />
                                        </td>
                                        <td className='current_tournaments_table_td'>
                                            <TrashFill className='general_icon' />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className='current_tournaments_pagination'>
                    <Pagination>
                        {renderPaginationItems()}
                    </Pagination>
                </div>
            </div >
        </div >
    );
};

export default TournamentManage;