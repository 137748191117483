import React, { useContext, useEffect, useRef, useState } from 'react';
import '../CreateTournament/CreateTournament.css';
import { Col, Container, FloatingLabel, Form, Nav, OverlayTrigger, Popover, Row, Tab } from 'react-bootstrap';
import { ICreateTournamentViewModel } from '../../viewModels/tournament/ICreateTournamentViewModel';
import { TournamentConstants } from '../../helpers/constants/tournamentConstants';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { AppContext } from '../../components/AppContext';
import { getAllMaps, updateCS2Tournament } from '../../http/tournament/cs2TournamentActionsAPI';
import { TournamentTypeEnum } from '../../models/enums/tournament/TournamentTypeEnum';
import { MatchBracketTypeEnum } from '../../models/enums/tournament/MatchBracketTypeEnum';
import { FinalStageTypeEnum } from '../../models/enums/tournament/FinalStageTypeEnum';
import { MatchesFormatEnum } from '../../models/enums/tournament/MatchesFormatEnum';
import { ICS2MapModel } from '../../models/dto/cs2/ICS2MapModel';
import { ArrowClockwise, InfoCircleFill, PlusCircle, Search, X } from 'react-bootstrap-icons';
import MyAlert from '../../components/MyAlert/MyAlert';
import { ICS2CreateTournamentViewModel } from '../../viewModels/tournament/CS2CreateTournamentViewModel';
import { DateRangePicker, DatePicker, CustomProvider } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { ruRU } from 'rsuite/esm/locales';
import { getPlayersByNicknamePart } from '../../http/playerActionsAPI';
import { IPlayerSelectorPlayerModel } from '../../components/PlayerSelector/Models/IPlayerSelectorPlayerModel';
import { useModal } from '../../hooks/useModal';
import { SystemRolesEnum } from '../../models/enums/SystemRolesEnum';
import GooeyButton from '../../components/Buttons/GooeyButton/GooeyButton';
import { calculateThirdPlaceDate, getFullTournamentInfoById } from '../../http/tournament/tournamentActionsAPI';

const avatars = [
    'tournament_avatar1.jpg',
    'tournament_avatar2.jpg',
    'tournament_avatar3.jpg'
];

const UpdateTournament: React.FC = observer(() => {
    const { player } = useContext(AppContext);
    const { tournamentId } = useParams();
    const [validated, setValidated] = useState(false);
    const [isRoundSystemChecked, setIsRoundSystemChecked] = useState(false);
    const [isStandardSystemChecked, setIsStandardSystemChecked] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [selectedMaps, setSelectedMaps] = useState<string[]>([]);
    const [allMaps, setAllMaps] = useState<ICS2MapModel[]>([]);
    const [showCompetitive, setShowCompetitive] = useState(false);
    
    const [selectedTournamentTypeEnumBottom, setSelectedTournamentTypeEnumBottom] = useState(false);
    const [randomAvatarIdx, setRandomAvatarIdx] = useState(0);
    const [currentAvatar, setCurrentAvatar] = useState<string>(`${process.env.REACT_APP_IMG_MINIO_URL}${avatars[randomAvatarIdx]}`);
    const { beforeToday } = DateRangePicker;
    const navigate = useNavigate();
    const [alertText, setAlertText] = useState('');
    const [groupOptions, setGroupOptions] = useState<number[]>([2, 4]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    
    const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);
    const [minDateForThirdPlaceMatch, setMinDateForThirdPlaceMatch] = useState<Date | null>(null);
    const maxDateForThirdPlaceMatch = minDateForThirdPlaceMatch ? new Date(minDateForThirdPlaceMatch.getTime() + 14 * 24 * 60 * 60 * 1000) : null;
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState<IPlayerSelectorPlayerModel[] | null>([]);
    const [additionalMembers, setAdditionalMembers] = useState<IPlayerSelectorPlayerModel[]>([]);
    const [memberRoles, setMemberRoles] = useState<{ playerId: string, role: string }[]>([]);
    const { isVisible: showMyAlert, openModal: handleShowMyAlert, closeModal: handleCloseMyAlert } = useModal();
    const { isVisible: showCreateAlert, openModal: handleShowCreateAlert, closeModal: handleCloseCreateAlert } = useModal();
    const [tournamentAdmins, setTournamentAdmins] = useState<string[]>([]);
    const [tournamentStreamers, setTournamentStreamers] = useState<string[]>([]);
    const [tournamentObservers, setTournamentObservers] = useState<string[]>([]);
    const [selectedPlayerId, setSelectedPlayerId] = useState('');
    
    const tournamentTypeMap = {
        OnlyRobin: TournamentTypeEnum.OnlyRobin,
        OnlySingle: TournamentTypeEnum.OnlySingle,
        OnlyDouble: TournamentTypeEnum.OnlyDouble,
        RobinWithSingle: TournamentTypeEnum.RobinWithSingle,
        RobinWithDouble: TournamentTypeEnum.RobinWithDouble
    };
    
    const matchBracketTypeMap: { [key: string]: MatchBracketTypeEnum } = {
        BO1: MatchBracketTypeEnum.BO1,
        BO2: MatchBracketTypeEnum.BO2,
        BO3: MatchBracketTypeEnum.BO3,
    };  
    
    const finalStageTypeMap: { [key: string]: FinalStageTypeEnum } = {
        BO1: FinalStageTypeEnum.BO1,
        BO3: FinalStageTypeEnum.BO3,
        BO5: FinalStageTypeEnum.BO5,
    };  
    
    const matchesFormatMap: { [key: string]: MatchesFormatEnum } = {
        VS1X1: MatchesFormatEnum.VS1X1,
        VS2X2: MatchesFormatEnum.VS2X2,
        V31X3: MatchesFormatEnum.VS3X3,
        VS5X5: MatchesFormatEnum.VS5X5,
    };
    
    const mapMemderRoles = (member: IPlayerSelectorPlayerModel) => {
        let mappingPlayerRoles = [];
        
        if (member && member.playerId) {
            if (tournamentObservers.includes(member.playerId)) {
                mappingPlayerRoles.push('Observer');
            }
            
            if (tournamentAdmins.includes(member.playerId)) {
                mappingPlayerRoles.push('Admin');
            }
            
            if (tournamentStreamers.includes(member.playerId)) {
                mappingPlayerRoles.push('Streamer');
            }
            
            if (
                !tournamentStreamers.includes(member.playerId) && 
                !tournamentAdmins.includes(member.playerId) &&
                !tournamentObservers.includes(member.playerId)
            ) {
                mappingPlayerRoles.push('');
            }
        }
        
        return mappingPlayerRoles;
    }
    
    const convertUTCToLocal = (utcHour: number): number => {
        const now = new Date();
        
        const timezoneOffsetMinutes = now.getTimezoneOffset();
        
        const timezoneOffsetHours = -timezoneOffsetMinutes / 60;
        
        const localHour = utcHour + timezoneOffsetHours;
        
        return (localHour + 24) % 24;
    };
    
    const filteredMaps = showCompetitive
        ? allMaps.filter((map) => map.isCompetitive)
        : allMaps;
    
    const [cs2Tournament, setCs2Tournament] = useState<ICS2CreateTournamentViewModel>({
        mapBanSeconds: 15,
        cs2MapGuids: selectedMaps,
        pauseDuration: 30,
        pauseQuantity: 1,
        freezeTime: 5
    });
    
    const [tournamentUdateData, setTournamentUdateData] = useState<ICreateTournamentViewModel>({
        organizedBy: SystemRolesEnum.Player, // TODO Partner
        playerOrganizatorId: player?.playerId,
        partnerOrganizatorId: '', // TODO Partner
        name: '',
        gameTypeId: TournamentConstants.CS2.gameTypeId,
        description: '',
        location: '',
        prizePool: null,
        logoFile: undefined,
        logoStringUrl: undefined,
        materialPrizes: null,
        maxSlots: 8,
        tournamentTypeEnum: TournamentTypeEnum.OnlySingle,
        upperMatchBracketTypeEnum: MatchBracketTypeEnum.BO3,
        matchesFormatEnum: isStandardSystemChecked ? MatchesFormatEnum.VS5X5 : 0,
        isThirdPlaceEnabled: false,
        thirdPlaceDate: '',
        firstMatchDate: '',
        parallelMatchesCount: 1,
        startMatchHour: 10,
        endMatchHour: 20,
        regDateStart: '',
        regDateEnd: '',
        firstPlaceDate: '',
        countTeamInGroup: 2,
        fastCupFlag: false,
        parallelFinals: false,
        // staff
        tournamentStreamers: [],
        tournamentPlayerAdmins: [],
        tournamentObservers: [],
        lobbyStaffWaiting: false,
        additionalSlots: -1,
        coachesAllowedInMatches: false,
        
        // cs2
        cS2TournamentVM: cs2Tournament,
    });
    
    useEffect(() => {
        const fetchTournamentData = async () => {
            setLoading(true);
            if (tournamentId) {
                try {
                    await getFullTournamentInfoById(tournamentId)
                        .then((data) => {
                            if (data) {
                                setSelectedMaps(data.cS2TournamentModel?.cS2MapGuids ?? []);
                                
                                setCs2Tournament({
                                    mapBanSeconds: data.cS2TournamentModel?.mapBanSeconds ?? 15,
                                    cs2MapGuids: data.cS2TournamentModel?.cS2MapGuids ?? [],
                                    pauseDuration: data.cS2TournamentModel?.pauseDuration ?? 30,
                                    pauseQuantity: data.cS2TournamentModel?.pauseQuantity ?? 1,
                                    freezeTime: data.cS2TournamentModel?.freezeTime ?? 5
                                });
                                
                                setTournamentUdateData({
                                    organizedBy: SystemRolesEnum.Player,              
                                    playerOrganizatorId: player?.playerId,
                                    partnerOrganizatorId: '',                         
                                    name: data.name ?? '',
                                    gameTypeId: data.gameTypeId ?? '',
                                    description: data.description ?? '',
                                    location: data.location ?? '',
                                    prizePool: data.prizePool ?? null,
                                    logoFile: undefined,
                                    logoStringUrl: data.logoUrl ?? undefined,
                                    materialPrizes: null,
                                    maxSlots: data.maxSlots ?? 8,
                                    tournamentTypeEnum: data.tournamentTypeEnum ?? null,
                                    upperMatchBracketTypeEnum: data.upperMatchBracketTypeEnum ?? MatchBracketTypeEnum.BO1,
                                    finalStageTypeEnum: data.finalStageTypeEnum ?? FinalStageTypeEnum.BO1,
                                    thirdPlaceTypeEnum: data.thirdPlaceTypeEnum ?? FinalStageTypeEnum.BO1,
                                    matchesFormatEnum: data.matchesFormatEnum ?? (isStandardSystemChecked ? MatchesFormatEnum.VS5X5 : 0),
                                    isThirdPlaceEnabled: data.isThirdPlaceEnabled ?? false,
                                    thirdPlaceDate: data.thirdPlaceDate,
                                    firstMatchDate: data.firstMatchDate ?? '',
                                    parallelMatchesCount: data.parallelMatchesCount ?? 1,
                                    startMatchHour: data.startMatchHour,
                                    endMatchHour: data.endMatchHour,
                                    regDateStart: data.regDateStart ?? '',
                                    regDateEnd: data.regDateEnd ?? '',
                                    firstPlaceDate: data.firstPlaceDate ?? '',
                                    countTeamInGroup: data.countTeamInGroup ?? 2,
                                    tournamentStreamers: [],
                                    tournamentPlayerAdmins: [],
                                    tournamentObservers: [],
                                    lobbyStaffWaiting: data.lobbyStaffWaiting ?? false,
                                    additionalSlots: data.additionalSlots,
                                    coachesAllowedInMatches: data.coachesAllowedInMatches ?? false,
                                    fastCupFlag: data.fastCupFlag ?? false,
                                    parallelFinals: data.parallelFinals ?? false,
                                    cS2TournamentVM: cs2Tournament
                                });
                                data.observers.forEach((obs) => {
                                    setTournamentObservers((prev) => {
                                        return [...prev, obs.playerId];
                                    });
                                });
                                data.playerAdmins.forEach((obs) => {
                                    setTournamentAdmins((prev) => {
                                        return [...prev, obs.playerId];
                                    });
                                });
                                data.streamers.forEach((obs) => {
                                    setTournamentStreamers((prev) => {
                                        return [...prev, obs.playerId];
                                    });
                                });
                                setAdditionalMembers([
                                    ...data.observers, 
                                    ...data.playerAdmins, 
                                    ...data.streamers
                                ]);
                                setDateRange([
                                    new Date(data.startDate), 
                                    new Date(data.endDate)
                                ]);
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                } catch (error) {
                    console.error(error);
                    setLoading(false);
                } finally {
                    setLoading(false);
                }
            }
        };
        
        fetchTournamentData();
    }, [tournamentId]);
    
    useEffect(() => {
        if (tournamentUdateData.tournamentTypeEnum !== null) {
            const tournamentTypeKey = TournamentTypeEnum[tournamentUdateData.tournamentTypeEnum] as keyof typeof tournamentTypeMap;
            const tournamentTypeFromBackend = tournamentTypeMap[tournamentTypeKey];
            
            if (TournamentTypeEnum.OnlyRobin === tournamentTypeFromBackend) {
                setIsRoundSystemChecked(true);
                setIsStandardSystemChecked(false);
            } else {
                setIsStandardSystemChecked(true);
                setIsRoundSystemChecked(false);
            }
        }
    }, [tournamentUdateData]);
    
    const popover = (
        <Popover >
            <Popover.Header as="h6">Обратите внимание</Popover.Header>
            <Popover.Body>
                При выборе дат учитывается Ваш <strong>текущий часовой пояс</strong>. Не используйте VPN.
            </Popover.Body>
        </Popover>
    );
    const popoverFastCup = (
        <Popover >
            <Popover.Body>
                Быстрый турнир, максимум 16 команд. Матчи следуют друг за другом, в расписании время матчей будет указано приблизительно.
            </Popover.Body>
        </Popover>
    );
    const popoverPersonal = (
        <Popover >
            <Popover.Header as="h6">Назначение стафа на турнир</Popover.Header>
            <Popover.Body>
                <strong>Зависит от выбранного количества доп слотов! </strong> <br />
                Админ - управление игровым сервером.<br />
                Стример - трансляция игры на внешних платформах. <br />
                Обсервер - мониторинг внутри игры.
            </Popover.Body>
        </Popover>
    );
    
    const rotateRight = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setRandomAvatarIdx((prev) => (prev + 1) % avatars.length);
        setCurrentAvatar(`${process.env.REACT_APP_IMG_MINIO_URL}${avatars[randomAvatarIdx]}`);
        
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            setTournamentUdateData(prev => ({ ...prev, logoFile: undefined }))
        }
    };
    
    const handleMapClick = (mapId: string) => {
        setSelectedMaps((prevSelectedMaps) => {
            const newSelectedMaps = prevSelectedMaps.includes(mapId)
                ? prevSelectedMaps.filter((selectedId) => selectedId !== mapId)
                : [...prevSelectedMaps, mapId];
            setCs2Tournament((prevCs2Tournament) => ({
                ...prevCs2Tournament,
                mapIds: newSelectedMaps,
            }));
            return newSelectedMaps;
        });
    };
    
    const handleCheckboxChange = () => {
        setShowCompetitive((prevShowCompetitive) => !prevShowCompetitive);
    };
    
    const updateGroupOptions = (maxSlots: number) => {
        const options = [];
        for (let i = 2; i <= maxSlots / 2; i++) {
            if (maxSlots % i === 0) {
                options.push(i);
            }
        }
        setGroupOptions(options);
        setTournamentUdateData(prev => ({ ...prev, countTeamInGroup: 0 }));
    };
    
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | null,
        customName?: string
    ) => {
        if (!e) return;
        
        const { name: targetName, value, type } = e.target;
        const name = customName || targetName;
        
        if (name === 'maxSlots') {
            updateGroupOptions(parseInt(value));
        }
        
        if (type === 'checkbox') {
            const isChecked = (e.target as HTMLInputElement).checked;
            setTournamentUdateData(prevTournament => ({ ...prevTournament, [name]: isChecked }));
        } else {
            setTournamentUdateData(prevTournament => ({
                ...prevTournament,
                [name]: value
            }));
        }
    };
    
    const handleCS2TournamentVMChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        const parsedValue = isNaN(Number(value)) ? value : Number(value);
        
        setCs2Tournament(prevTournament => {
            const updatedTournament = {
                ...prevTournament,
                [name]: parsedValue,
            };
            setTournamentUdateData(prev => ({
                ...prev,
                cS2TournamentVM: updatedTournament,
            }));
            
            return updatedTournament;
        });
    };
    
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
                setTournamentUdateData(prevTournament => ({ ...prevTournament, logoFile: file }));
                const imageUrl = URL.createObjectURL(file);
                setCurrentAvatar(imageUrl);
            } else {
                alert('Пожалуйста, выберите файл изображения (PNG или JPEG).');
            }
        }
    };
    
    const adjustHoursForTimezone = (hour: number) => {
        const currentDate = new Date();
        const timezoneOffsetHours = currentDate.getTimezoneOffset() / 60;
        return hour + timezoneOffsetHours;
    };
    
    const handleUpdateTournament = async (e: React.FormEvent) => {
        e.preventDefault();
        setValidated(true);
        setLoading(true);
        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity() === false) {
            setLoading(false);
            e.stopPropagation();
            return;
        }
        
        const rolesNotSelected = additionalMembers.some(member =>
            !memberRoles.some(role => role.playerId === member.playerId)
        );
        
        if (rolesNotSelected) {
            setLoading(false);
            setAlertText("Заполните роли для персонала")
            handleShowCreateAlert();
            return;
        }
        if (!tournamentUdateData.firstMatchDate || isNaN(Date.parse(tournamentUdateData.firstMatchDate))) {
            setLoading(false);
            setAlertText("Укажите дату старта матчей");
            handleShowCreateAlert();
            return;
        }
        if (tournamentUdateData.cS2TournamentVM) {
            tournamentUdateData.cS2TournamentVM.cs2MapGuids = selectedMaps;
        }
        
        const formData = new FormData();
        if (tournamentUdateData.logoFile) {
            formData.append('logoFile', tournamentUdateData.logoFile ?? '');
        } else {
            formData.append('logoStringUrl', avatars[randomAvatarIdx]);
        }
        formData.append('playerOrganizatorId', tournamentUdateData.playerOrganizatorId || '');
        formData.append('partnerOrganizatorId', tournamentUdateData.partnerOrganizatorId || '');
        formData.append('organizedBy', String(tournamentUdateData.organizedBy) || '');
        formData.append('name', tournamentUdateData.name);
        formData.append('gameTypeId', tournamentUdateData.gameTypeId || '');
        formData.append('description', tournamentUdateData.description);
        formData.append('location', tournamentUdateData.location);
        formData.append('prizePool', tournamentUdateData.prizePool?.toString() || '');
        formData.append('materialPrizes', tournamentUdateData.materialPrizes || '');
        formData.append('maxSlots', tournamentUdateData.maxSlots.toString());
        formData.append('tournamentTypeEnum', tournamentUdateData.tournamentTypeEnum?.toString() || '');
        formData.append('upperMatchBracketTypeEnum', tournamentUdateData.upperMatchBracketTypeEnum.toString());
        formData.append('lowerMatchBracketTypeEnum', tournamentUdateData.lowerMatchBracketTypeEnum?.toString() || '');
        
        formData.append('finalStageTypeEnum', tournamentUdateData.finalStageTypeEnum?.toString() || '');
        formData.append('matchesFormatEnum', tournamentUdateData.matchesFormatEnum.toString());
        formData.append('isThirdPlaceEnabled', tournamentUdateData.isThirdPlaceEnabled ? 'true' : 'false');
        formData.append('thirdPlaceTypeEnum', tournamentUdateData.thirdPlaceTypeEnum?.toString() || '');
        formData.append('firstMatchDate', tournamentUdateData.firstMatchDate);
        formData.append('parallelMatchesCount', tournamentUdateData.parallelMatchesCount.toString());
        formData.append('startMatchHour', adjustHoursForTimezone(tournamentUdateData.startMatchHour).toString());
        formData.append('endMatchHour', adjustHoursForTimezone(tournamentUdateData.endMatchHour).toString());
        formData.append('regDateStart', tournamentUdateData.regDateStart);
        formData.append('regDateEnd', tournamentUdateData.regDateEnd);
        formData.append('firstPlaceDate', tournamentUdateData.firstPlaceDate);
        formData.append('fastCupFlag', tournamentUdateData.fastCupFlag ? 'true' : 'false');
        formData.append('parallelFinals', tournamentUdateData.parallelFinals ? 'true' : 'false');
        
        tournamentAdmins.forEach((admin, index) => {
            formData.append(`tournamentPlayerAdmins[${index}]`, admin);
        });
        tournamentStreamers.forEach((streamer, index) => {
            formData.append(`tournamentStreamers[${index}]`, streamer);
        });
        tournamentObservers.forEach((observer, index) => {
            formData.append(`tournamentObservers[${index}]`, observer);
        });
        formData.append('lobbyStaffWaiting', tournamentUdateData.lobbyStaffWaiting ? 'true' : 'false');
        const totalAdditionalSlots = tournamentUdateData.additionalSlots === -1 ? 0 : tournamentUdateData.additionalSlots;
        formData.append('additionalSlots', totalAdditionalSlots?.toString() || '');
        formData.append('coachesAllowedInMatches', tournamentUdateData.coachesAllowedInMatches ? 'true' : 'false');
        if (tournamentUdateData.isThirdPlaceEnabled) {
            formData.append('thirdPlaceDate', tournamentUdateData.thirdPlaceDate || '');
        }
        
        formData.append('cS2TournamentVM.mapBanSeconds', cs2Tournament?.mapBanSeconds?.toString() || '');
        tournamentUdateData.cS2TournamentVM?.cs2MapGuids?.forEach((mapId, index) => {
            formData.append(`cS2TournamentVM.mapIds[${index}]`, mapId);
        });
        formData.append('cS2TournamentVM.pauseDuration', cs2Tournament?.pauseDuration?.toString() || '');
        formData.append('cS2TournamentVM.pauseQuantity', cs2Tournament?.pauseQuantity?.toString() || '');
        formData.append('cS2TournamentVM.freezeTime', cs2Tournament?.freezeTime?.toString() || '');
        
        switch (tournamentUdateData.gameTypeId) {
            case TournamentConstants.CS2.gameTypeId:
                if (tournamentId) {
                    updateCS2Tournament(formData, tournamentId)
                        .then((response) => {
                            setLoading(false);
                            if (response) {
                                navigate(`/tournament/${tournamentId}`);
                            } else {
                                setAlertText("Ошибка при создании турнира. Заполните обязательные поля");
                                handleShowCreateAlert();
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            setAlertText("Ошибка при создании турнира");
                            handleShowCreateAlert();
                        });
                    break;
                }
        }
    };
    
    const handleTimeChange = (value: any, field: string) => {
        if (value) {
            const hours = value.getHours();
            setTournamentUdateData(prev => ({
                ...prev,
                [field]: hours
            }));
        } else {
            setTournamentUdateData(prev => ({
                ...prev,
                [field]: ''
            }));
        }
    };
    
    const handleRangeChange = (range: [Date, Date] | null) => {
        if (!range) {
            setDateRange(null);
            setTournamentUdateData(prev => ({
                ...prev,
                regDateStart: '',
                regDateEnd: ''
            }));
        } else {
            const [start, end] = range;
            setDateRange([start, end]);
            setTournamentUdateData(prev => ({
                ...prev,
                regDateStart: start.toISOString(),
                regDateEnd: end.toISOString()
            }));
        }
    };
    
    const handleDateChange = (value: Date | null, field: string) => {
        if (!value) {
            setTournamentUdateData(prev => ({ ...prev, [field]: '' }));
            return;
        }
        const updatedDate = value.toISOString();
        setTournamentUdateData(prev => ({ ...prev, [field]: updatedDate }));
    };
    
    const handleInputSearch = (e: any) => {
        let value: string = e.target.value as string;
        if (value.length >= 3) { setSearchValue(value); }
    };
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSearchSubmit(e);
        }
    };
    const handleSearchSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const results = await getPlayersByNicknamePart(searchValue);
        setSearchResults(results);
    };
    const addAdditionalMembers = (selectedPlayer: IPlayerSelectorPlayerModel) => {
        if (tournamentUdateData.additionalSlots) {
            if (additionalMembers.length >= tournamentUdateData.additionalSlots) { return }
        } else {
            return;
        }
        setAdditionalMembers(prev => {
            const isPlayerAlreadyAdded = prev.some(player => player.playerId === selectedPlayer.playerId);
            
            if (isPlayerAlreadyAdded) {
                return prev;
            }
            
            return [...prev, selectedPlayer];
        });
    }
    const removeSelectedMember = (selectedPlayerModel: IPlayerSelectorPlayerModel) => {
        setAdditionalMembers(prevMembers => prevMembers.filter(member => member.playerId !== selectedPlayerModel.playerId));
    }
    const addMemberByRole = (selectedRole: string, selectedMemberId: string) => {
        const removePlayerFromCurrentRole = (playersArray: string[]) => {
            return playersArray.filter(player => player !== selectedMemberId);
        };
        
        setTournamentAdmins(currentAdmins => removePlayerFromCurrentRole(currentAdmins));
        setTournamentStreamers(currentStreamers => removePlayerFromCurrentRole(currentStreamers));
        setTournamentObservers(currentObservers => removePlayerFromCurrentRole(currentObservers));
        
        switch (selectedRole) {
            case 'Admin':
                setTournamentAdmins(currentAdmins => [...currentAdmins, selectedMemberId]);
                break;
            
            case 'Streamer':
                setTournamentStreamers(currentStreamers => [...currentStreamers, selectedMemberId]);
                break;
            
            case 'Observer':
                setTournamentObservers(currentObservers => [...currentObservers, selectedMemberId]);
                break;
            
            default:
                break;
        }
        
        setMemberRoles(prevRoles => {
            const updatedRoles = prevRoles.filter(member => member.playerId !== selectedMemberId);
            if (selectedRole) {
                updatedRoles.push({ playerId: selectedMemberId, role: selectedRole });
            }
            return updatedRoles;
        });
    };
    const handleShowMyAlertForMember = (playerId: string) => {
        setSelectedPlayerId(playerId);
        handleShowMyAlert();
    }
    
    const handleRoundSystemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setIsRoundSystemChecked(isChecked);
        if (isChecked) {
            setIsStandardSystemChecked(false);
            setTournamentUdateData({
                ...tournamentUdateData,
                tournamentTypeEnum: TournamentTypeEnum.OnlyRobin,
                lowerMatchBracketTypeEnum: null,
            });
            setSelectedTournamentTypeEnumBottom(false);
        } else {
            setTournamentUdateData({
                ...tournamentUdateData,
                tournamentTypeEnum: null,
                lowerMatchBracketTypeEnum: null,
            });
        }
    };
    
    useEffect(() => {
        setCurrentAvatar(`${process.env.REACT_APP_IMG_MINIO_URL}${avatars[randomAvatarIdx]}`);
    }, [randomAvatarIdx]);
    
    useEffect(() => {
        if (tournamentUdateData?.additionalSlots && tournamentUdateData?.additionalSlots <= 0) {
            setAdditionalMembers([]);
        } else if (tournamentUdateData?.additionalSlots && additionalMembers.length > tournamentUdateData?.additionalSlots) {
            setAdditionalMembers(additionalMembers.slice(0, tournamentUdateData?.additionalSlots));
        }
    }, [tournamentUdateData?.additionalSlots]);
    
    useEffect(() => {
        let result = getAllMaps()
            .then((r) => {
                if (!result) {
                    console.log(`Cannot get cs2 maps!`);
                } else {
                    setAllMaps(r as ICS2MapModel[]);
                }
            })
            .catch((r) => {
                console.log(`Getting map error: ${r}`);
            });
    }, [])
    
    useEffect(() => {
        if (tournamentUdateData.tournamentTypeEnum === TournamentTypeEnum.OnlyRobin) {
            setTournamentUdateData(prev => ({ ...prev, isThirdPlaceEnabled: false }));
        } else if (tournamentUdateData.tournamentTypeEnum === TournamentTypeEnum.OnlyDouble || tournamentUdateData.tournamentTypeEnum === TournamentTypeEnum.RobinWithDouble) {
            setTournamentUdateData(prev => ({ ...prev, isThirdPlaceEnabled: true }));
        }
    }, [tournamentUdateData.tournamentTypeEnum]);
    
    useEffect(() => {
        setTournamentUdateData(prev => ({
            ...prev,
            finalStageTypeEnum: isStandardSystemChecked ? FinalStageTypeEnum.BO1 : null,
            matchesFormatEnum: isStandardSystemChecked ? MatchesFormatEnum.VS5X5 : 0,
            lowerMatchBracketTypeEnum: selectedTournamentTypeEnumBottom ? MatchBracketTypeEnum.BO1 : null,
            thirdPlaceTypeEnum: selectedTournamentTypeEnumBottom ? FinalStageTypeEnum.BO1 : null
        }));
    }, [isStandardSystemChecked, selectedTournamentTypeEnumBottom]);
    
    useEffect(() => {
        if (tournamentUdateData.firstMatchDate && !tournamentUdateData.fastCupFlag) {
            const calculateData = {
                teamsCount: tournamentUdateData.maxSlots,
                startMatchesDate: tournamentUdateData.firstMatchDate,
                startMatchHour: tournamentUdateData.startMatchHour,
                endMatchHour: tournamentUdateData.endMatchHour,
                upperTypeMatch: tournamentUdateData.upperMatchBracketTypeEnum,
                lowerTypeMatch: tournamentUdateData.lowerMatchBracketTypeEnum,
                parallelMatches: tournamentUdateData.parallelMatchesCount
            }
            calculateThirdPlaceDate(calculateData)
                .then(baseDate => {
                    if (baseDate) {
                        let adjustedDate = new Date(baseDate);
                        let startHour = tournamentUdateData.startMatchHour;
                        let endHour = tournamentUdateData.endMatchHour;
                        let matchHour = adjustedDate.getHours();
                        
                        if (matchHour < startHour || matchHour > endHour) {
                            adjustedDate.setDate(adjustedDate.getDate() + 1);
                            adjustedDate.setHours(startHour, 0, 0, 0);
                        }
                        
                        let minDateForFinal = new Date(adjustedDate);
                        if (!tournamentUdateData.parallelFinals && tournamentUdateData.isThirdPlaceEnabled && tournamentUdateData.thirdPlaceTypeEnum !== undefined && tournamentUdateData.thirdPlaceTypeEnum !== null) {
                            const additionalHours: any = { 0: 1, 1: 3, 2: 5 };
                            const hoursToAdd = additionalHours[tournamentUdateData.thirdPlaceTypeEnum] || 0;
                            minDateForFinal.setHours(minDateForFinal.getHours() + hoursToAdd);
                            setTournamentUdateData(prev => ({
                                ...prev,
                                thirdPlaceDate: adjustedDate.toISOString(),
                                firstPlaceDate: minDateForFinal.toISOString()
                            }));
                        } else {
                            if (tournamentUdateData.parallelFinals) {
                                setTournamentUdateData(prev => ({
                                    ...prev,
                                    thirdPlaceDate: adjustedDate.toISOString()
                                }));
                            }
                            setTournamentUdateData(prev => ({
                                ...prev,
                                firstPlaceDate: adjustedDate.toISOString()
                            }));
                        }
                        setMinDateForThirdPlaceMatch(adjustedDate);
                    }
                })
                .catch(error => {
                    console.error('Error fetching min date for match:', error);
                });
        } else {
            setTournamentUdateData(prev => ({
                ...prev,
                thirdPlaceDate: '',
                firstPlaceDate: '',
                regDateEnd: '',
                regDateStart: ''
            }));
        }
    }, [
        tournamentUdateData.firstMatchDate,
        tournamentUdateData.maxSlots,
        tournamentUdateData.startMatchHour,
        tournamentUdateData.endMatchHour,
        tournamentUdateData.upperMatchBracketTypeEnum,
        tournamentUdateData.lowerMatchBracketTypeEnum,
        tournamentUdateData.parallelMatchesCount,
        tournamentUdateData.isThirdPlaceEnabled,
        tournamentUdateData.thirdPlaceTypeEnum,
        tournamentUdateData.fastCupFlag,
        tournamentUdateData.parallelFinals
    ]);
    
    useEffect(() => {
        if (tournamentUdateData.parallelFinals && tournamentUdateData.thirdPlaceDate) {
            setTournamentUdateData(prev => ({
                ...prev,
                firstPlaceDate: tournamentUdateData.thirdPlaceDate || ''
            }));
        }
    }, [tournamentUdateData.parallelFinals, tournamentUdateData.thirdPlaceDate]);
    
    useEffect(() => {
        if (!tournamentUdateData.isThirdPlaceEnabled && tournamentUdateData.parallelFinals) {
            setTournamentUdateData(prev => ({
                ...prev,
                parallelFinals: false
            }));
        }
    }, [tournamentUdateData.isThirdPlaceEnabled, tournamentUdateData.parallelFinals]);
    
    useEffect(() => {
        if (tournamentUdateData.fastCupFlag) {
            setTournamentUdateData(prev => ({
                ...prev,
                firstPlaceDate: '',
                thirdPlaceDate: ''
            }));
        }
    }, [tournamentUdateData.fastCupFlag]);
    
    const tournamentTypeMapping: { [key: string]: TournamentTypeEnum } = {
        "OnlyRobin": TournamentTypeEnum.OnlyRobin,
        "OnlySingle": TournamentTypeEnum.OnlySingle,
        "OnlyDouble": TournamentTypeEnum.OnlyDouble,
        "RobinWithSingle": TournamentTypeEnum.RobinWithSingle,
        "RobinWithDouble": TournamentTypeEnum.RobinWithDouble,
    };
    
    return (
        <Container>
            <div className="create_tournament_container">
                <div className="create_tournament_header">
                    <div>РЕДАКТИРОВАНИЕ ТУРНИРА <span>{tournamentUdateData.name}</span></div>
                    <GooeyButton onClick={handleUpdateTournament} isLoading={isLoading} disabled={isLoading}>
                        РЕДАКТИРОВАТЬ ТУРНИР
                    </GooeyButton>
                </div>
                <MyAlert
                    show={showCreateAlert}
                    onHide={handleCloseCreateAlert}
                    alertText={alertText}
                    buttons={[
                        { text: 'РЕДАКТИРОВАТЬ', onClick: handleCloseCreateAlert }
                    ]}
                />
                <div className="create_tournament_main_container">
                    <Tab.Container defaultActiveKey="first">
                        <Row>
                            <Col sm={3}>
                                <Nav className="create-tournament-navs" variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" className="create-tournament-navlink">ОСНОВНОЕ</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">ИГРОВЫЕ НАСТРОЙКИ</Nav.Link>
                                    </Nav.Item>
                                    {(isStandardSystemChecked || isRoundSystemChecked) && (
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">НАСТРОЙКИ ВРЕМЕНИ</Nav.Link>
                                        </Nav.Item>
                                    )}
                                    {tournamentUdateData.gameTypeId != null && (
                                        <Nav.Item>
                                            <Nav.Link eventKey="fourth">ДОПОЛНИТЕЛЬНО</Nav.Link>
                                        </Nav.Item>
                                    )}
                                    {tournamentUdateData?.additionalSlots && tournamentUdateData?.additionalSlots > 0 ? (
                                        <Nav.Item>
                                            <Nav.Link eventKey="fifth" className="create-tournament-navlink">ДОП СЛОТЫ</Nav.Link>
                                        </Nav.Item>
                                    ) : <></>}
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form className="create_tournament_form" noValidate validated={validated}>
                                            <FloatingLabel label="ИГРОВАЯ ДИСЦИПЛИНА" className='create_tourn_label'>
                                                <Form.Select
                                                    className="create_tournament_form_control option"
                                                    name="gameTypeId"
                                                    defaultValue={tournamentUdateData?.gameTypeId ?? undefined}
                                                    value={tournamentUdateData.gameTypeId || ''}
                                                    required
                                                    onChange={handleInputChange}
                                                >
                                                    <option value={TournamentConstants.CS2.gameTypeId}>CS2</option>
                                                    <option value={TournamentConstants.DOTA2.gameTypeId} disabled >DOTA2</option>
                                                    <option value="3" disabled >Fortnite</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                            <FloatingLabel label="НАЗВАНИЕ ТУРНИРА" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="name"
                                                    placeholder=""
                                                    defaultValue={tournamentUdateData?.name ?? ''}
                                                    value={tournamentUdateData.name || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    maxLength={50}
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="ОПИСАНИЕ ТУРНИРА" className='create_tourn_label'>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={6}
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="description"
                                                    placeholder=""
                                                    defaultValue={tournamentUdateData?.description || ''}
                                                    value={tournamentUdateData.description || ''}
                                                    onChange={handleInputChange}
                                                    maxLength={350}
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="МЕСТО ПРОВЕДЕНИЯ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="location"
                                                    placeholder=""
                                                    defaultValue={tournamentUdateData?.location || ''}
                                                    value={tournamentUdateData.location || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="ПРИЗОВОЙ ФОНД" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    name="prizePool"
                                                    type="number"
                                                    placeholder=""
                                                    defaultValue={tournamentUdateData?.prizePool || ''}
                                                    value={tournamentUdateData.prizePool || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                />
                                            </FloatingLabel>
                                            <Form.Label>
                                                ЛОГОТИП ТУРНИРА
                                            </Form.Label>
                                            
                                            <div className='createTournament_download_avatar_container'>
                                                <h3 className='createTournament_download_avatar_title'>Загрузите или выберите аватар</h3>
                                                <div className='createTournament_download_avatar'>
                                                    <Form.Group controlId="formFile" className="create_tournament_form_control date">
                                                        <Form.Control
                                                            ref={fileInputRef}
                                                            type="file"
                                                            name="logoFile"
                                                            accept="image/png, image/jpeg"
                                                            onChange={handleFileChange}
                                                        />
                                                    </Form.Group>
                                                    <div className='createTournament_avatar_container'>
                                                        <div className='createTournament_avatar'>
                                                            <img src={currentAvatar} alt="User Avatar" />
                                                            <button className='createTournament_avatar_button' onClick={rotateRight}>
                                                                <ArrowClockwise className='avatar_button_arrowIcon' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <Form.Label>
                                                    ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ
                                                </Form.Label>
                                                <Form.Group controlId="formFile" className="create-tournament-form-control">
                                                    <Form.Control type="file" />
                                                 </Form.Group> */}
                                        </Form>
                                    </Tab.Pane>
                                    
                                    <Tab.Pane eventKey="second">
                                        <Form className="create_tournament_form" noValidate validated={validated}>
                                            <Form.Check
                                                className="create_tournament_checkbox"
                                                type="checkbox"
                                                name="fastCupFlag"
                                                label={(
                                                    <>
                                                        FAST CUP <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popoverFastCup}>
                                                            <InfoCircleFill className='create_tournament_info' />
                                                        </OverlayTrigger>
                                                    </>
                                                )}
                                                // defaultChecked={tournamentUdateData.fastCupFlag}
                                                checked={tournamentUdateData.fastCupFlag}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    setTournamentUdateData(prevTournament => ({
                                                        ...prevTournament,
                                                        fastCupFlag: isChecked,
                                                    }));
                                                }}
                                            />
                                            <FloatingLabel label="МАКСИМАЛЬНОЕ КОЛИЧЕСТВО КОМАНД" className='create_tourn_label'>
                                                <Form.Select
                                                    className="create_tournament_form_control option"
                                                    name="maxSlots"
                                                    onChange={handleInputChange}
                                                    value={tournamentUdateData?.maxSlots}
                                                    required
                                                >
                                                    <option value={8}>8</option>
                                                    <option value={16}>16</option>
                                                    {!tournamentUdateData.fastCupFlag && (
                                                        <>
                                                            <option value={32}>32</option>
                                                            <option value={64}>64</option>
                                                            <option value={128}>128</option>
                                                        </>
                                                    )}
                                                </Form.Select>
                                            </FloatingLabel>
                                            
                                            <Form.Check
                                                className="create_tournament_checkbox"
                                                type="checkbox"
                                                name="isRoundSystem"
                                                checked={isRoundSystemChecked} 
                                                disabled={isStandardSystemChecked}  
                                                onChange={handleRoundSystemChange}
                                                label="КРУГОВАЯ СИСТЕМА"
                                            />
                                            
                                            <Form.Check
                                                className="create_tournament_checkbox"
                                                type="checkbox"
                                                name="isStandardSystem"
                                                checked={isStandardSystemChecked}
                                                disabled={isRoundSystemChecked}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    setIsStandardSystemChecked(isChecked);
                                                    if (isChecked) {
                                                        setIsRoundSystemChecked(false);
                                                        setTournamentUdateData((prev) => ({
                                                            ...prev,
                                                            tournamentTypeEnum: TournamentTypeEnum.OnlySingle,
                                                            lowerMatchBracketTypeEnum: undefined,
                                                        }));
                                                    } else {
                                                        setTournamentUdateData((prev) => ({
                                                            ...prev,
                                                            tournamentTypeEnum: null,
                                                            lowerMatchBracketTypeEnum: undefined,
                                                        }));
                                                        setSelectedTournamentTypeEnumBottom(false);
                                                    }
                                                }}
                                                label="СТАНДАРТНАЯ СИСТЕМА"
                                            />
                                            
                                            {isStandardSystemChecked && (
                                                <FloatingLabel label="ТИП ТУРНИРА" className='create_tourn_label'>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="tournamentTypeEnum"
                                                        onChange={(e) => {
                                                            const selectedValue = parseInt(e.target.value);
                                                            setTournamentUdateData({ ...tournamentUdateData, tournamentTypeEnum: selectedValue });
                                                            if (selectedValue === TournamentTypeEnum.RobinWithDouble || selectedValue === TournamentTypeEnum.OnlyDouble) {
                                                                setSelectedTournamentTypeEnumBottom(true);
                                                            } else {
                                                                setSelectedTournamentTypeEnumBottom(false);
                                                            }
                                                        }}
                                                        defaultValue={
                                                            (tournamentTypeMapping[tournamentUdateData?.tournamentTypeEnum as unknown as string] || '').toString()
                                                        }
                                                    >
                                                        {isRoundSystemChecked ? (
                                                            <>
                                                                <option disabled value={TournamentTypeEnum.RobinWithSingle}>КРУГОВАЯ + SINGLE ELIMINATION</option>
                                                                <option disabled value={TournamentTypeEnum.RobinWithDouble}>КРУГОВАЯ + DOUBLE ELIMINATION</option>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <option value={TournamentTypeEnum.OnlySingle}>SINGLE ELIMINATION</option>
                                                                <option value={TournamentTypeEnum.OnlyDouble}>DOUBLE ELIMINATION</option>
                                                            </>
                                                        )}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            )}
                                            {(isRoundSystemChecked || isStandardSystemChecked) && (
                                                <FloatingLabel
                                                    label={tournamentUdateData.tournamentTypeEnum === TournamentTypeEnum.OnlyDouble || tournamentUdateData.tournamentTypeEnum === TournamentTypeEnum.RobinWithDouble ? 'ФОРМАТ МАТЧЕЙ ВЕРХНЕЙ СЕТКИ' : 'ФОРМАТ МАТЧЕЙ'}
                                                    className='create_tourn_label'>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="upperMatchBracketTypeEnum"
                                                        onChange={handleInputChange}
                                                        value={matchBracketTypeMap[tournamentUdateData.upperMatchBracketTypeEnum as keyof typeof matchBracketTypeMap]}
                                                    >
                                                        <option value={MatchBracketTypeEnum.BO1}>BO1</option>
                                                        {tournamentUdateData.tournamentTypeEnum === TournamentTypeEnum.OnlyRobin && (
                                                            <option value={MatchBracketTypeEnum.BO2}>BO2</option>
                                                        )}
                                                        <option value={MatchBracketTypeEnum.BO3}>BO3</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            )}
                                            {selectedTournamentTypeEnumBottom && (
                                                <FloatingLabel label="ФОРМАТ МАТЧЕЙ НИЖНЕЙ СЕТКИ" className='create_tourn_label'>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="lowerMatchBracketTypeEnum"
                                                        onChange={handleInputChange}
                                                        value={matchBracketTypeMap[tournamentUdateData.lowerMatchBracketTypeEnum as keyof typeof matchBracketTypeMap]}
                                                    >
                                                        <option value={MatchBracketTypeEnum.BO1}>BO1</option>
                                                        <option value={MatchBracketTypeEnum.BO3}>BO3</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            )}
                                            {isRoundSystemChecked && (
                                                <FloatingLabel label="КОЛИЧЕСТВО КОМАНД В ГРУППЕ" className='create_tourn_label'>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="countTeamInGroup"
                                                        onChange={handleInputChange}
                                                        value={tournamentUdateData?.countTeamInGroup || ''}
                                                        required
                                                    >
                                                        {groupOptions.map(option => (
                                                            <option key={option} value={option}>{option}</option>
                                                        ))}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            )}
                                            {isStandardSystemChecked && (
                                                <>
                                                    <FloatingLabel label="ФИНАЛЬНАЯ СТАДИЯ" className='create_tourn_label'>
                                                        <Form.Select
                                                            className="create_tournament_form_control option"
                                                            name="finalStageTypeEnum"
                                                            onChange={handleInputChange}
                                                            value={finalStageTypeMap[tournamentUdateData.finalStageTypeEnum as keyof typeof finalStageTypeMap]}
                                                        >
                                                            <option value={FinalStageTypeEnum.BO1}>BO1</option>
                                                            <option value={FinalStageTypeEnum.BO3}>BO3</option>
                                                            <option value={FinalStageTypeEnum.BO5}>BO5</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    <FloatingLabel label="ФОРМАТ ПРОТИВОСТОЯНИЙ" className='create_tourn_label'>
                                                        <Form.Select
                                                            className="create_tournament_form_control option"
                                                            name="matchesFormatEnum"
                                                            onChange={handleInputChange}
                                                            defaultValue={tournamentUdateData?.matchesFormatEnum || ''}
                                                            value={matchesFormatMap[tournamentUdateData.matchesFormatEnum as keyof typeof matchesFormatMap]}
                                                        >
                                                            <option value={MatchesFormatEnum.VS1X1}>1 на 1</option>
                                                            <option value={MatchesFormatEnum.VS2X2}>2 на 2</option>
                                                            <option value={MatchesFormatEnum.VS3X3}>3 на 3</option>
                                                            <option value={MatchesFormatEnum.VS5X5}>5 на 5</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </>
                                            )}
                                            {isStandardSystemChecked && (
                                                <>
                                                    <Form.Check
                                                        className="create_tournament_checkbox"
                                                        type="checkbox"
                                                        name="isThirdPlaceEnabled"
                                                        checked={tournamentUdateData.isThirdPlaceEnabled}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setTournamentUdateData(prevTournament => ({
                                                                ...prevTournament,
                                                                isThirdPlaceEnabled: isChecked,
                                                                thirdPlaceTypeEnum: isChecked ? FinalStageTypeEnum.BO1 : null
                                                            }));
                                                        }}
                                                        label="МАТЧ ЗА ТРЕТЬЕ МЕСТО"
                                                        disabled={tournamentUdateData.tournamentTypeEnum === TournamentTypeEnum.OnlyRobin || tournamentUdateData.tournamentTypeEnum === TournamentTypeEnum.OnlyDouble || tournamentUdateData.tournamentTypeEnum === TournamentTypeEnum.RobinWithDouble}
                                                    />
                                                    {tournamentUdateData.isThirdPlaceEnabled && (
                                                        <FloatingLabel label="ФОРМАТ МАТЧА ЗА 3 МЕСТО" className='create_tourn_label'>
                                                            <Form.Select
                                                                className="create_tournament_form_control option"
                                                                name="thirdPlaceTypeEnum"
                                                                onChange={handleInputChange}
                                                                value={finalStageTypeMap[tournamentUdateData.thirdPlaceTypeEnum as keyof typeof finalStageTypeMap]}
                                                            >
                                                                <option value={FinalStageTypeEnum.BO1}>BO1</option>
                                                                <option value={FinalStageTypeEnum.BO3}>BO3</option>
                                                                <option value={FinalStageTypeEnum.BO5}>BO5</option>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    )}
                                                </>
                                            )}
                                        </Form>
                                    </Tab.Pane>
                                    
                                    <Tab.Pane eventKey="third">
                                        <CustomProvider theme="dark" locale={ruRU}>
                                            <Form className="create_tournament_form">
                                                {!tournamentUdateData.fastCupFlag && (
                                                    <>
                                                        <Form.Label>
                                                            ДАТЫ РЕГИСТРАЦИИ НА ТУРНИР
                                                            <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popover}>
                                                                <InfoCircleFill className='create_tournament_info' />
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                        <DateRangePicker
                                                            className="create_tournament_form_control"
                                                            format="dd/MM/yyyy HH:mm"
                                                            placeholder="Выбор"
                                                            defaultValue={dateRange}
                                                            onChange={handleRangeChange}
                                                            onClean={() => handleRangeChange(null)}
                                                            ranges={[]}
                                                            shouldDisableDate={beforeToday()}
                                                        />
                                                    </>
                                                )}
                                                <>
                                                    <Form.Label>
                                                        ИГРОВОЙ ДЕНЬ
                                                    </Form.Label>
                                                    <Form.Group className="time_management_create_tournament_group"  >
                                                        <div className="time_management_create_tournament_group_container time">
                                                            <DatePicker
                                                                className='time-picker'
                                                                format="HH:mm"
                                                                cleanable={false}
                                                                hideHours={(hour) => hour < 0 || hour > 23}
                                                                hideMinutes={minute => minute !== 0}
                                                                value={tournamentUdateData.startMatchHour !== null ? 
                                                                    new Date(0, 0, 0, convertUTCToLocal(tournamentUdateData.startMatchHour)) : 
                                                                    null}
                                                                onChange={(value) => handleTimeChange(value, 'startMatchHour')}
                                                                ranges={[]}
                                                            />
                                                            <span> - </span>
                                                            <DatePicker
                                                                className='time-picker'
                                                                format="HH:mm"
                                                                cleanable={false}
                                                                hideHours={(hour) => hour < 0 || hour > 23}
                                                                hideMinutes={minute => minute !== 0}
                                                                defaultValue={tournamentUdateData.endMatchHour !== null ? 
                                                                    new Date(0, 0, 0, convertUTCToLocal(tournamentUdateData.endMatchHour)) : 
                                                                    null}
                                                                onChange={(value) => handleTimeChange(value, 'endMatchHour')}
                                                                ranges={[]}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Label className="time_management_create_tournament_group_label">
                                                        КОЛИЧЕСТВО ПАРАЛЛЕЛЬНЫХ МАТЧЕЙ
                                                    </Form.Label>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="parallelMatchesCount"
                                                        value={tournamentUdateData?.parallelMatchesCount || ''}
                                                        required
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                    </Form.Select>
                                                    {tournamentUdateData.isThirdPlaceEnabled &&
                                                        (
                                                            <Form.Check
                                                                className="create_tournament_checkbox"
                                                                type="checkbox"
                                                                name="parallelFinals"
                                                                checked={tournamentUdateData.parallelFinals}
                                                                onChange={(e) => {
                                                                    const isChecked = e.target.checked;
                                                                    setTournamentUdateData(prevTournament => ({
                                                                        ...prevTournament,
                                                                        parallelFinals: isChecked
                                                                    }));
                                                                }}
                                                                label="ИГРАТЬ ФИНАЛ И МАТЧ ЗА ТРЕТЬЕ МЕСТО ПАРАЛЛЕЛЬНО"
                                                            />
                                                        )}
                                                    {tournamentUdateData.parallelMatchesCount && tournamentUdateData.startMatchHour && tournamentUdateData.endMatchHour ? (
                                                        <>
                                                            <Form.Label>
                                                                ДАТА СТАРТА МАТЧЕЙ
                                                            </Form.Label>
                                                            <div>
                                                                <DatePicker
                                                                    className="create_tournament_form_control"
                                                                    format="dd/MM/yyyy HH:mm"
                                                                    name="firstMatchDate"
                                                                    defaultValue={tournamentUdateData?.firstMatchDate ? new Date(tournamentUdateData?.firstMatchDate) : null}
                                                                    value={tournamentUdateData.firstMatchDate ? new Date(tournamentUdateData.firstMatchDate) : null}
                                                                    onChange={(value) => handleDateChange(value, 'firstMatchDate')}
                                                                    shouldDisableHour={(hour, date) => {
                                                                        const regDateEnd = new Date(tournamentUdateData.regDateEnd);
                                                                        const selectedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                                                                        const regEndDateOnly = new Date(regDateEnd.getFullYear(), regDateEnd.getMonth(), regDateEnd.getDate());
                                                                        
                                                                        if (selectedDate.getTime() === regEndDateOnly.getTime()) {
                                                                            // Ограничения в день окончания регистрации
                                                                            if (tournamentUdateData.startMatchHour < tournamentUdateData.endMatchHour) {
                                                                                // Игровой день в пределах одних суток
                                                                                return hour <= regDateEnd.getHours() || hour < tournamentUdateData.startMatchHour || hour > tournamentUdateData.endMatchHour;
                                                                            } else {
                                                                                // Игровой день переходит через полночь
                                                                                return hour <= regDateEnd.getHours() || !(hour >= tournamentUdateData.startMatchHour || hour < tournamentUdateData.endMatchHour);
                                                                            }
                                                                        } else {
                                                                            // Обычные ограничения для других дней
                                                                            if (tournamentUdateData.startMatchHour < tournamentUdateData.endMatchHour) {
                                                                                return hour < tournamentUdateData.startMatchHour || hour > tournamentUdateData.endMatchHour;
                                                                            } else {
                                                                                return !(hour >= tournamentUdateData.startMatchHour || hour < tournamentUdateData.endMatchHour);
                                                                            }
                                                                        }
                                                                    }}
                                                                    hideMinutes={(minute) => minute !== 0}
                                                                    shouldDisableMinute={minute => minute !== 0}
                                                                    shouldDisableDate={(date) => {
                                                                        const regDateEnd = tournamentUdateData.regDateEnd ? new Date(tournamentUdateData.regDateEnd) : new Date();
                                                                        const regEndDateOnly = new Date(regDateEnd.getFullYear(), regDateEnd.getMonth(), regDateEnd.getDate());
                                                                        const selectedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                                                                        return selectedDate < regEndDateOnly;
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    ) : <></>}
                                                    {!tournamentUdateData.fastCupFlag && tournamentUdateData.firstMatchDate && tournamentUdateData.isThirdPlaceEnabled && tournamentUdateData.tournamentTypeEnum !== TournamentTypeEnum.OnlyRobin && (
                                                        <>
                                                            <Form.Label>
                                                                ДАТА МАТЧА ЗА ТРЕТЬЕ МЕСТО
                                                            </Form.Label>
                                                            <div>
                                                                <DatePicker
                                                                    className="create_tournament_form_control"
                                                                    format="dd/MM/yyyy HH:mm"
                                                                    placeholder="Выбор"
                                                                    name="thirdPlaceDate"
                                                                    shouldDisableHour={(hour, date) => {
                                                                        if (date.toDateString() === minDateForThirdPlaceMatch?.toDateString()) {
                                                                            return hour < minDateForThirdPlaceMatch.getHours() || hour > tournamentUdateData.endMatchHour;
                                                                        }
                                                                        return hour < tournamentUdateData.startMatchHour || hour > tournamentUdateData.endMatchHour;
                                                                    }}
                                                                    hideMinutes={(minute) => minute !== 0}
                                                                    shouldDisableMinute={minute => minute !== 0}
                                                                    ranges={[]}
                                                                    cleanable={false}
                                                                    defaultValue={tournamentUdateData?.thirdPlaceDate ? new Date(tournamentUdateData?.thirdPlaceDate) : null}
                                                                    value={tournamentUdateData.thirdPlaceDate ? new Date(tournamentUdateData.thirdPlaceDate) : null}
                                                                    onChange={(value) => handleDateChange(value, 'thirdPlaceDate')}
                                                                    shouldDisableDate={(date) => {
                                                                        const isBeforeMinDate = minDateForThirdPlaceMatch ? date < minDateForThirdPlaceMatch : true;
                                                                        const isAfterMaxDate = maxDateForThirdPlaceMatch ? date > maxDateForThirdPlaceMatch : false;
                                                                        return isBeforeMinDate || isAfterMaxDate;
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                    {!tournamentUdateData.fastCupFlag && tournamentUdateData.firstMatchDate && tournamentUdateData.tournamentTypeEnum !== TournamentTypeEnum.OnlyRobin && (
                                                        <>
                                                            <Form.Label>
                                                                ДАТА ФИНАЛА
                                                            </Form.Label>
                                                            <div>
                                                                <DatePicker
                                                                    className="create_tournament_form_control"
                                                                    format="dd/MM/yyyy HH:mm"
                                                                    placeholder="Выбор"
                                                                    name="firstPlaceDate"
                                                                    disabled={tournamentUdateData.parallelFinals ? true : false}
                                                                    hideMinutes={(minute) => minute !== 0}
                                                                    shouldDisableHour={(hour, date) => {
                                                                        const minDateForFinal = new Date(tournamentUdateData.firstPlaceDate);
                                                                        if (date.toDateString() === minDateForFinal.toDateString()) {
                                                                            if (tournamentUdateData.startMatchHour < tournamentUdateData.endMatchHour) {
                                                                                return hour < minDateForFinal.getHours() || hour < tournamentUdateData.startMatchHour || hour > tournamentUdateData.endMatchHour;
                                                                            } else {
                                                                                return hour < minDateForFinal.getHours() || !(hour >= tournamentUdateData.startMatchHour || hour < tournamentUdateData.endMatchHour);
                                                                            }
                                                                        } else {
                                                                            if (tournamentUdateData.startMatchHour < tournamentUdateData.endMatchHour) {
                                                                                return hour < tournamentUdateData.startMatchHour || hour > tournamentUdateData.endMatchHour;
                                                                            } else {
                                                                                return !(hour >= tournamentUdateData.startMatchHour || hour < tournamentUdateData.endMatchHour);
                                                                            }
                                                                        }
                                                                    }}
                                                                    ranges={[]}
                                                                    cleanable={false}
                                                                    defaultValue={tournamentUdateData?.firstPlaceDate ? new Date(tournamentUdateData?.firstPlaceDate) : null}
                                                                    value={tournamentUdateData.firstPlaceDate ? new Date(tournamentUdateData.firstPlaceDate) : null}
                                                                    onChange={(value) => handleDateChange(value, 'firstPlaceDate')}
                                                                    shouldDisableDate={(date) => {
                                                                        const minFinalDate = new Date(tournamentUdateData.firstPlaceDate);
                                                                        const isBeforeMinDate = minFinalDate ? date < minFinalDate : false;
                                                                        const isAfterMaxDate = maxDateForThirdPlaceMatch ? date > new Date(maxDateForThirdPlaceMatch) : false;
                                                                        return isBeforeMinDate || isAfterMaxDate;
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            </Form>
                                        </CustomProvider>
                                    </Tab.Pane>
                                    
                                    {tournamentUdateData.gameTypeId === TournamentConstants.CS2.gameTypeId && (
                                        <Tab.Pane eventKey="fourth">
                                            <Form className="create_tournament_form">
                                                <FloatingLabel label="ДОПОЛНИТЕЛЬНЫЕ СЛОТЫ" className='create_tourn_label'>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="additionalSlots"
                                                        defaultValue={tournamentUdateData?.additionalSlots || ''}
                                                        value={tournamentUdateData.additionalSlots}
                                                        required
                                                        onChange={handleCS2TournamentVMChange}
                                                    >
                                                        <option value={-1}>0</option>
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                        <option value={4}>4</option>
                                                        <option value={5}>5</option>
                                                        <option value={6}>6</option>
                                                        <option value={7}>7</option>
                                                        <option value={8}>8</option>
                                                        <option value={9}>9</option>
                                                        <option value={10}>10</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                                <Form.Check
                                                    className="create_tournament_checkbox"
                                                    type="checkbox"
                                                    name="lobbyStaffWaiting"
                                                    defaultChecked={tournamentUdateData.lobbyStaffWaiting}
                                                    onChange={(e) => {
                                                        const isChecked = e.target.checked;
                                                        setTournamentUdateData(prevTournament => ({
                                                            ...prevTournament,
                                                            lobbyStaffWaiting: isChecked
                                                        }));
                                                    }}
                                                    label="Ожидать готовность персонала в игровом лобби"
                                                />
                                                <Form.Check
                                                    className="create_tournament_checkbox"
                                                    type="checkbox"
                                                    name="coachesAllowedInMatches"
                                                    defaultChecked={tournamentUdateData.coachesAllowedInMatches}
                                                    onChange={(e) => {
                                                        const isChecked = e.target.checked;
                                                        setTournamentUdateData(prevTournament => ({
                                                            ...prevTournament,
                                                            coachesAllowedInMatches: isChecked
                                                        }));
                                                    }}
                                                    label="Разрешить присутствие тренера в матче"
                                                />
                                                <FloatingLabel label="КОЛИЧЕСТВО ИГРОВЫХ ПАУЗ НА МАТЧ" className='create_tourn_label'>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="pauseQuantity"
                                                        value={cs2Tournament.pauseQuantity || ''}
                                                        required
                                                        onChange={handleCS2TournamentVMChange}
                                                    >
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                                <FloatingLabel label="ПРОДОЛЖИТЕЛЬНОСТЬ ИГРОВЫХ ПАУЗ (сек.)" className='create_tourn_label'>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="pauseDuration"
                                                        value={cs2Tournament.pauseDuration || ''}
                                                        required
                                                        onChange={handleCS2TournamentVMChange}
                                                    >
                                                        <option value={30}>30</option>
                                                        <option value={60}>60</option>
                                                        <option value={90}>90</option>
                                                        <option value={120}>120</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                                <FloatingLabel label="ВРЕМЯ НА ВЫБОР КАРТ (сек.)" className='create_tourn_label'>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="mapBanSeconds"
                                                        onChange={handleCS2TournamentVMChange}
                                                        value={cs2Tournament.mapBanSeconds || ''}
                                                    >
                                                        <option value={15}>15</option>
                                                        <option value={30}>30</option>
                                                        <option value={45}>45</option>
                                                        <option value={60}>60</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                                <FloatingLabel label="FREEZETIME (сек.)" className='create_tourn_label'>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="freezeTime"
                                                        onChange={handleCS2TournamentVMChange}
                                                        value={cs2Tournament.freezeTime || ''}
                                                    >
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={15}>15</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                                <div className='сs2_mapselection_container'>
                                                    <div className='сs2_mapselection_container_header'>ВЫБОР КАРТ ТУРНИРА</div>
                                                    <Form.Check
                                                        className="create_tournament_checkbox"
                                                        type="checkbox"
                                                        checked={showCompetitive}
                                                        onChange={handleCheckboxChange}
                                                        label="Выбрать только соревновательные карты"
                                                    />
                                                    <div className='сs2_mapselection_maps'>
                                                        {filteredMaps.map((map) => (
                                                            <div
                                                                key={map.mapId}
                                                                style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_MINIO_URL}${map.mapImgUrl})` }}
                                                                onClick={() => handleMapClick(map.mapId)}
                                                                className={selectedMaps.includes(map.mapId) ? 'selected' : ''}>
                                                                <p className='cs2_mapselection_maps_title'>{map.name}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Form>
                                        </Tab.Pane>
                                    )}
                                    
                                    <Tab.Pane eventKey="fifth">
                                        <Form className="create_tournament_form">
                                            <div className='added_membersContainer'>
                                                {additionalMembers.map((member) => (
                                                    <>
                                                        {mapMemderRoles(member).map(m => (
                                                            <div key={member.playerId} className='teammates__teamcreate_unit'>
                                                                <X className='team_member_unit_name_x' onClick={() => handleShowMyAlertForMember(member.playerId || '')} />
                                                                <img className='teammates__teamcreate_avatar' src={`${process.env.REACT_APP_IMG_MINIO_URL}${member.avatarUrl}`} alt="" />
                                                                <div className='teammates__teamcreate_name' onClick={() => { window.open(`/player/${member.playerId}`, '_blank') }}>{member.playerName}</div>
                                                                
                                                                <Form.Select
                                                                    className="create_tournament_form_control option"
                                                                    name="memberRole"
                                                                    onChange={e => { addMemberByRole(e.target.value, member.playerId || '') }}
                                                                    defaultValue={m}
                                                                >
                                                                    <option value="">Роль</option>
                                                                    <option value="Admin">Админ</option>
                                                                    <option value="Streamer">Стример</option>
                                                                    <option value="Observer">Обсервер</option>
                                                                </Form.Select>
                                                                
                                                                <MyAlert
                                                                    onHide={handleCloseMyAlert}
                                                                    show={showMyAlert}
                                                                    alertText='Вы точно хотите удалить пользователя?'
                                                                    buttons={[
                                                                        {
                                                                            text: 'Да',
                                                                            onClick: () => {
                                                                                removeSelectedMember({
                                                                                    playerId: selectedPlayerId,
                                                                                    playerName: '',
                                                                                    avatarUrl: ''
                                                                                });
                                                                                handleCloseMyAlert();
                                                                            }
                                                                        },
                                                                        { text: 'Нет', onClick: handleCloseMyAlert }
                                                                    ]}
                                                                />
                                                            </div>
                                                        ))}
                                                    </>
                                                ))}
                                            </div>
                                            
                                            <div className='create_last_form_control'>
                                                <Form.Label>
                                                    ДОБАВЛЕНИЕ ПЕРСОНАЛА {additionalMembers.length}/{tournamentUdateData.additionalSlots}
                                                    <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popoverPersonal}>
                                                        <InfoCircleFill className='create_tournament_info' />
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                
                                                <div className='search_team_top'>
                                                    <Form className="search_team_bar">
                                                        <Form.Control
                                                            name="search"
                                                            autoComplete="off"
                                                            placeholder="ВВЕДИТЕ НИКНЕЙМ"
                                                            onChange={handleInputSearch}
                                                            onKeyDown={handleKeyDown}
                                                            className="rounded-pill"
                                                        />
                                                    </Form>
                                                    
                                                    <div className='search_team_topButton'>
                                                        <button type="submit" className='submit_team_button' onClick={handleSearchSubmit}><Search /></button>
                                                    </div>
                                                </div>
                                                
                                                {searchResults !== null && searchResults.length > 0 && (
                                                    <div className="players_selecor_block">
                                                        {searchResults?.map((result) => (
                                                            <div className="searched_players_nickname" key={result.playerId} >
                                                                <div className='searched_players_nickname_logo'>
                                                                    <img className='searched_players_nickname_logo' src={`${process.env.REACT_APP_IMG_MINIO_URL}${result.avatarUrl}`} alt="" />
                                                                </div>
                                                                <div className='searched_players_nickname_playername' onClick={() => { window.open(`/player/${result.playerId}`, '_blank') }}>
                                                                    {result.playerName}
                                                                </div>
                                                                <PlusCircle className='searched_players_nickname_plus' onClick={() => addAdditionalMembers(result)} size={27} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </Form>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </Container >
    );
});

export default UpdateTournament;