import { authInstance } from "..";

export const createDota2Tournament = async (
    tournamentData: FormData
): Promise<string | false> => {
    try {
        const response = await authInstance.post('Dota2Tournaments/CreateTournament', tournamentData);
        const { tournamentId } = response.data;
        if (response.status === 200 && tournamentId) {
            return tournamentId;
        } else {
            console.log('Unexpected response or missing tournamentId');
            return false;
        }
    } catch (e: any) {
        console.log(e.response?.data?.message || 'Error creating tournament');
        return false;
    }
};