import React, { useEffect, useState } from 'react';
import './ChosenGame.css';
import { Container, Pagination } from 'react-bootstrap';
import TournamentCell from '../../components/TournamentCell/TournamentCell';
import { useParams } from 'react-router';
import { getTournamentsByFilters } from '../../http/tournament/tournamentActionsAPI';
import { ICommonTournamentModel } from '../../models/dto/tournament/ICommonTournamentModel';
import { Steam } from 'react-bootstrap-icons';
import { TournamentConstants } from '../../helpers/constants/tournamentConstants';
import CustomSpinner1 from '../../components/CustomSpinners/CustomSpinner1';
import { TournamentSF } from '../../viewModels/filters/TournamentSF';
import { TournamentStatusesEnum } from '../../models/enums/tournament/TournamentStatusesEnum';

const ChosenGame: React.FC = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [fetchingTournamentsList, setFetchingTournamentsList] = useState(true);
    const { gameTypeId } = useParams<{ gameTypeId: string }>();
    const gameTypeIdValue: string = gameTypeId ?? '';
    const [gameTypeIdsSelected, setGameTypeIdsSelected] = useState<string[]>([gameTypeIdValue]);

    const [activeTournamentsList, setActiveTournamentsList] = useState<ICommonTournamentModel[]>([]);
    const [pastTournamentsList, setPastTournamentsList] = useState<ICommonTournamentModel[]>([]);

    const [currentActivePage, setCurrentActivePage] = useState<number>(1);
    const [totalActivePages, setTotalActivePages] = useState<number>(1);

    const [currentPastPage, setCurrentPastPage] = useState<number>(1);
    const [totalPastPages, setTotalPastPages] = useState<number>(1);

    const [filters, setFilters] = useState<TournamentSF>({
        gameTypeIds: gameTypeIdsSelected,
        page: 1,
        limit: 12
    });

    useEffect(() => {
        if (gameTypeId) {
            setFetchingTournamentsList(true);
            const updatedActiveFilters = {
                ...filters,
                page: currentActivePage,
                gameTypeIds: gameTypeIdsSelected,
                tournamentStatuses: [
                    TournamentStatusesEnum.Approved,
                    TournamentStatusesEnum.Registration,
                    TournamentStatusesEnum.WaitForStartMatches,
                    TournamentStatusesEnum.GroupStage,
                    TournamentStatusesEnum.MainStage
                ]
            };

            const updatedPastFilters = {
                ...filters,
                page: currentPastPage,
                gameTypeIds: gameTypeIdsSelected,
                tournamentStatuses: [
                    TournamentStatusesEnum.Cancelled,
                    TournamentStatusesEnum.Finished
                ]
            };

            if (activeTab === 0) {
                getTournamentsByFilters(updatedActiveFilters)
                    .then(response => {
                        const data = response.tournaments;
                        const totalCount = response.totalCount;
                        if (data) {
                            setActiveTournamentsList(data);
                            const calculatedTotalPages = Math.ceil(totalCount / filters.limit);
                            setTotalActivePages(calculatedTotalPages > 0 ? calculatedTotalPages : 1);
                        }
                    })
                    .finally(() => setFetchingTournamentsList(false));
            } else {
                getTournamentsByFilters(updatedPastFilters)
                    .then(response => {
                        const data = response.tournaments;
                        const totalCount = response.totalCount;
                        if (data) {
                            setPastTournamentsList(data);
                            const calculatedTotalPages = Math.ceil(totalCount / filters.limit);
                            setTotalPastPages(calculatedTotalPages > 0 ? calculatedTotalPages : 1);
                        }
                    })
                    .finally(() => setFetchingTournamentsList(false));
            }
        }
    }, [gameTypeIdsSelected, currentActivePage, currentPastPage, activeTab, gameTypeId, filters]);

    const getGameConstsByTypeId = (gameTypeId: string | undefined) => {
        if (typeof gameTypeId !== 'string') {
            return null;
        }
        const games: any = TournamentConstants;

        for (const key in games) {
            if (games[key].gameTypeId === gameTypeId) {
                return games[key];
            }
        }
        return null;
    }

    const gameInfo = getGameConstsByTypeId(gameTypeId);

    const paginateActive = (pageNumber: number) => {
        setCurrentActivePage(pageNumber);
    };

    const paginatePast = (pageNumber: number) => {
        setCurrentPastPage(pageNumber);
    };

    const renderPaginationItems = (totalPages: number, currentPage: number, paginate: (pageNumber: number) => void) => {
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => paginate(number)}
                >
                    {number}
                </Pagination.Item>,
            );
        }
        return items;
    };

    return (
        <Container>
            <div className="chosen_game_container">
                <div className='chosen_game_first_container'>
                    <div className='headercard_container'>
                        <div className='chosen_game_info'>
                            <div className='chosen_game_short_info_pic_contaner'>
                                <img className='chosen_game_short_info_pic' src={gameInfo.gameImage} alt="chosen_game" />
                            </div>
                            <div className='chosen_game_short_info'>
                                <div>
                                    <div className='chosen_game_name'>
                                        {gameInfo.gameName}
                                    </div>
                                    <div className='chosen_game_genre'>
                                        {gameInfo.gameGenre}
                                    </div>
                                    <div className='chosen_game_socials'>
                                        <div className='chosen_game_socials_container'>
                                            <a href={gameInfo.gameSteamLink} target="_blank" rel="noopener noreferrer">
                                                <Steam size={30} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='chosen_game_socials_button_container'>
                                    <button className='chosen_game_socials_button active_tab'>
                                        ТУРНИРЫ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='chosen_game_short_stats'>
                            <div className='chosen_game_line'></div>
                            <div className='chosen_game_statistics'>
                                <div className='chosen_game_stat_number'>-</div>
                                <div className='chosen_game_stat_letters'>СЫГРАНО МАТЧЕЙ</div>
                            </div>
                            <div className='chosen_game_line'></div>
                            <div className='chosen_game_statistics'>
                                <div className='chosen_game_stat_number'>-</div>
                                <div className='chosen_game_stat_letters'>МАКСИМУМ ОНЛАЙН</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='chosen_game_second_container'>
                    <div className='chosen_game_slogan1'>
                        ОТКРОЙ ДЛЯ СЕБЯ МИР КИБЕРСПОРТА
                    </div>
                    <div className='chosen_game_slogan2'>
                        ВЫБЕРИ ТУРНИР ПО ДУШЕ
                    </div>
                </div>
                <div className='chosen_game_socials_button_container lists'>
                    <button
                        className={activeTab === 0 ? 'chosen_game_socials_button active_tab' : 'chosen_game_socials_button'}
                        onClick={() => setActiveTab(0)}
                    >
                        ТЕКУЩИЕ
                    </button>
                    <button
                        className={activeTab === 1 ? 'chosen_game_socials_button active_tab' : 'chosen_game_socials_button'}
                        onClick={() => setActiveTab(1)}
                    >
                        ПРОШЕДШИЕ
                    </button>
                </div>
                <div className='tournaments_with_pagination_container'>
                    {activeTab === 0 && (
                        <div className="chosen_game_third_maincontainer">
                            <div className="chosen_game_third_container">
                                {fetchingTournamentsList ? <CustomSpinner1 sizeSpinnerContainer='200px' size='100px' /> : (
                                    <>
                                        {activeTournamentsList.length === 0 ? (
                                            <div>Сейчас нет активных турниров</div>
                                        ) : (
                                            activeTournamentsList.map(tournament => (
                                                <TournamentCell key={tournament.tournamentId} tournament={tournament} />
                                            ))
                                        )}
                                    </>
                                )}
                            </div>
                            {activeTournamentsList.length > 0 && (
                                <div className="pagination_container">
                                    <Pagination>
                                        {renderPaginationItems(totalActivePages, currentActivePage, paginateActive)}
                                    </Pagination>
                                </div>
                            )}
                        </div>
                    )}
                    {activeTab === 1 && (
                        <div className="chosen_game_third_maincontainer">
                            <div className="chosen_game_third_container">
                                {fetchingTournamentsList ? <CustomSpinner1 sizeSpinnerContainer='200px' size='100px' /> : (
                                    <>
                                        {pastTournamentsList.length === 0 ? (
                                            <div>Еще нет прошедших турниров</div>
                                        ) : (
                                            pastTournamentsList.map(tournament => (
                                                <TournamentCell key={tournament.tournamentId} tournament={tournament} />
                                            ))
                                        )}
                                    </>
                                )}
                            </div>
                            {pastTournamentsList.length > 0 && (
                                <div className="pagination_container">
                                    <Pagination>
                                        {renderPaginationItems(totalPastPages, currentPastPage, paginatePast)}
                                    </Pagination>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default ChosenGame;