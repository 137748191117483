import React, { useContext, useEffect, useRef, useState } from 'react';
import '../CreateTeam/CreateTeam.css';
import { Container, Row, Tab, Col, Nav, Form, FloatingLabel, Popover, OverlayTrigger } from 'react-bootstrap';
import { ICreateTeamViewModel } from '../../viewModels/ICreateTeamViewModel';
import { AppContext } from '../../components/AppContext';
import { CommonConstants } from '../../helpers/constants/commonConstants';
import { getTeamInfo, updateTeam } from '../../http/playerActionsAPI';
import { ArrowClockwise, InfoCircleFill, X } from 'react-bootstrap-icons';
import { IPlayerSelectorPlayerModel } from '../../components/PlayerSelector/Models/IPlayerSelectorPlayerModel';
import { useModal } from '../../hooks/useModal';
import MyAlert from '../../components/MyAlert/MyAlert';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomProvider, DatePicker, DateRangePicker } from 'rsuite';
import { ruRU } from 'rsuite/esm/locales';
import { TournamentConstants } from '../../helpers/constants/tournamentConstants';
import { observer } from 'mobx-react-lite';
import { SystemRolesEnum } from '../../models/enums/SystemRolesEnum';
import { ITeamPageModel } from '../../models/dto/team/ITeamPageModel';
import { ICommonPlayerModel } from '../../models/dto/player/ICommonPlayerModel';
import { TeamParticipantEnum } from '../../models/enums/TeamParticipantEnum';
import { ITeamParticipantVM } from '../../viewModels/ITeamParticipantVM';
import PlayerSelector from '../../components/PlayerSelector/PlayerSelector';
import GooeyButton from '../../components/Buttons/GooeyButton/GooeyButton';

const avatars = [
    'team.png',
    'team2.png',
    'team3.png'
];

const UpdateTeam: React.FC = observer(() => {
    const [fetchingteamInfo, setFetchingteamInfo] = useState(true);
    const [teamInfo, setTeamInfo] = useState<ITeamPageModel>();
    const [isLoading, setIsLoading] = useState(false);
    const { player, partner } = useContext(AppContext);
    
    const navigate = useNavigate();
    const { teamId } = useParams();
    
    const [selectedPlayerId, setSelectedPlayerId] = useState<string | null>(null);
    const [randomAvatarIdx, setRandomAvatarIdx] = useState(0);
    const [validated, setValidated] = useState(false);
    const [validatedSecond, setValidatedSecond] = useState(false);
    const [currentAvatar, setCurrentAvatar] = useState<string>(`${process.env.REACT_APP_IMG_MINIO_URL}${teamInfo?.logoUrl}`);
    const fileInputRef = useRef<HTMLInputElement>(null);
    
    const { isVisible: showMyAlert, openModal: handleShowMyAlert, closeModal: handleCloseMyAlert } = useModal();
    const { isVisible: showTeamAlert, openModal: handleShowTeamAlert, closeModal: handleCloseTeamAlert } = useModal();
    
    const userSystemRole = player ? SystemRolesEnum.Player : SystemRolesEnum.Partner; // TODO: +Admin
    const creator = player ? {
        creatorId: player.playerId,
        creatorUserName: player.login,
        creatorAvatarURL: player.avatarURL,
    } : partner ? {
        creatorId: partner.partnerId,
        creatorUserName: partner.login,
        creatorAvatarURL: partner.logoUrl,
    } : { // TODO: Admin
        creatorId: null,
        creatorUserName: null,
        creatorAvatarURL: null,
    };
    
    const initTeamParticipants = (): ITeamParticipantVM[] => {
        if (!teamInfo) {
            return [];
        }
        
        const participantsMap: { [id: string]: ITeamParticipantVM } = {};
        
        const addParticipant = (id: string, role: TeamParticipantEnum) => {
            if (!participantsMap[id]) {
                participantsMap[id] = { participantId: id, participantTypes: [] };
            }
            participantsMap[id].participantTypes.push(role);
        };
        
        if (teamInfo.owner) {
            addParticipant(teamInfo.owner.partnerId, TeamParticipantEnum.Owner);
        }
        
        if (teamInfo.playerCreator) {
            addParticipant(teamInfo.playerCreator.playerId, TeamParticipantEnum.Creator);
        }
        
        if (teamInfo.partnerCreator) {
            addParticipant(teamInfo.partnerCreator.partnerId, TeamParticipantEnum.Creator);
        }
        
        addParticipant(teamInfo.captain.playerId, TeamParticipantEnum.Captain);
        
        teamInfo.players.forEach(player => addParticipant(player.playerId, TeamParticipantEnum.Player));
        teamInfo.coaches.forEach(coach => addParticipant(coach.playerId, TeamParticipantEnum.Coach));
        teamInfo.managers.forEach(manager => addParticipant(manager.playerId, TeamParticipantEnum.Manager));
        teamInfo.contentMakers.forEach(contentMaker => addParticipant(contentMaker.playerId, TeamParticipantEnum.ContentMaker));
        
        return Object.values(participantsMap);
    };
    const [teamParticipants, setTeamParticipants] = useState<ITeamParticipantVM[]>([]);
    
    let initPlayers = (): IPlayerSelectorPlayerModel[] => {
        let resultPlayers: IPlayerSelectorPlayerModel[] = [];
        
        teamInfo?.players.forEach(p => {
            resultPlayers.push({
                playerId: p.playerId,
                playerName: p.login,
                avatarUrl: p.avatarURL,
            });
        });
        
        return resultPlayers;
    };
    const [teamPlayers, setTeamPlayers] = useState<IPlayerSelectorPlayerModel[]>([]);
    
    const initStaff = (): IPlayerSelectorPlayerModel[] => {
        if (!teamInfo) return [];
        
        let staff: ICommonPlayerModel[] = [
            ...(teamInfo.captain ? [teamInfo.captain] : []),
            ...(teamInfo.coaches ?? []),
            ...(teamInfo.managers ?? []),
            ...(teamInfo.contentMakers ?? []),
        ];
        
        let staffMap: { [key: string]: IPlayerSelectorPlayerModel } = {};
        
        staff.forEach(p => {
            staffMap[p.playerId] = {
                playerId: p.playerId,
                playerName: p.login,
                avatarUrl: p.avatarURL ?? null,
            };
        }); 
        
        let resultStaff = Object.values(staffMap);
        
        return resultStaff;
    };
    const [teamStaff, setTeamStaff] = useState<IPlayerSelectorPlayerModel[]>([]);
    
    useEffect(() => {
        if (teamInfo) {
            setTeamStaff(initStaff());
            setTeamPlayers(initPlayers());
            setTeamParticipants(initTeamParticipants());
        }
    }, [teamInfo]);
    
    const [visibleRolesMemberId, setVisibleRolesMemberId] = useState<string | null>(null);
    const [participantRoles, setParticipantRoles] = useState<{ [key: string]: TeamParticipantEnum[] }>({});
    
    useEffect(() => {
        const roles = teamParticipants.reduce((acc, participant) => {
            acc[participant.participantId] = participant.participantTypes;
            return acc;
        }, {} as { [key: string]: TeamParticipantEnum[] });
        setParticipantRoles(roles);
    }, [teamParticipants]);
    
    const formRef1 = useRef<HTMLFormElement>(null);
    const formRef2 = useRef<HTMLFormElement>(null);
    
    useEffect(() => {
        setFetchingteamInfo(true);
        getTeamInfo(teamId)
            .then(data => {
                if (data) {
                    setTeamInfo(data);
                }
            })
            .catch(error => {
                console.error('Error fetching teams:', error);
            })
            .finally(() => setFetchingteamInfo(false));
    }, [teamId]);
    
    useEffect(() => {
        if (teamInfo?.players) {
            setTeamPlayers(teamInfo.players.map(player => ({
                playerId: player.playerId,
                playerName: player.login,
                avatarUrl: player.avatarURL
            })));
        }
    }, [teamInfo]);
    
    useEffect(() => {
        if (teamInfo?.logoUrl) {
            setCurrentAvatar(`${process.env.REACT_APP_IMG_MINIO_URL}${teamInfo.logoUrl}`);
        } else {
            setCurrentAvatar(`${process.env.REACT_APP_IMG_MINIO_URL}${avatars[randomAvatarIdx]}`);
        }
    }, [randomAvatarIdx, teamInfo?.logoUrl]);
    
    const [teamDataUpdate, setTeamDataUpdate] = useState<ICreateTeamViewModel>({
        initiatorId: player?.playerId ? player?.playerId : partner?.partnerId,
        creatorId: CommonConstants.GuidEmpty, // TODO Partner
        createdBy: userSystemRole,
        gameTypeId: '',
        city: '',
        name: '',
        tag: '',
        description: '',
        creationDate: '',
        score: 0,
        logoUrl: null,
        logoStringUrl: null,
        website: '',
        contactEmail: '',
        participants: []
    });
    
    useEffect(() => {
        if (teamInfo) {
            setTeamDataUpdate({
                initiatorId: player?.playerId ? player?.playerId : partner?.partnerId,
                creatorId: teamInfo.playerCreator?.playerId ?? CommonConstants.GuidEmpty, // TODO Partner
                createdBy: SystemRolesEnum.Player,
                gameTypeId: teamInfo.gameTypeId ?? '',
                city: teamInfo.city ?? '',
                name: teamInfo.name ?? '',
                tag: teamInfo.tag ?? '',
                description: teamInfo.description ?? '',
                creationDate: teamInfo.creationDate ?? '',
                score: teamInfo.score ?? 0,
                logoUrl: null,
                logoStringUrl: teamInfo.logoUrl ?? null,
                website: teamInfo.website ?? '',
                contactEmail: teamInfo.contactEmail ?? '',
                participants: []
            });
        }
    }, [teamInfo]);
    
    const rotateRight = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setRandomAvatarIdx((prev) => (prev + 1) % avatars.length);
        setCurrentAvatar(`${process.env.REACT_APP_IMG_MINIO_URL}${avatars[randomAvatarIdx]}`);
        
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            setTeamDataUpdate(prev => ({ ...prev, logoFile: undefined }))
        }
    };
    
    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | Date | null,
        name?: string
    ) => {
        if (event instanceof Date || event === null) {
            const value = event ? event.toISOString() : '';
            if (!name) return;
            
            setTeamDataUpdate(prevTeamData => ({
                ...prevTeamData,
                [name]: value
            }));
        } else {
            const { name, value, type, checked } = event.target as HTMLInputElement;
            if (type === 'checkbox') {
                setTeamDataUpdate(prevTeamData => ({
                    ...prevTeamData,
                    [name]: checked
                }));
            } else {
                setTeamDataUpdate(prevTeamData => ({
                    ...prevTeamData,
                    [name]: value
                }));
            }
        }
    };  
    
    const handleUpdateTeam = async (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);
        const form1 = formRef1.current;
        const form2 = formRef2.current;
        
        const isForm1Valid = form1?.checkValidity() ?? false;
        const isForm2Valid = form2?.checkValidity() ?? false;
        
        if (!isForm1Valid || !isForm2Valid) {
            if (!isForm1Valid) {
                setValidated(true);
            }
            if (!isForm2Valid) {
                setValidatedSecond(true);
            }
            handleShowTeamAlert();
            return;
        }
        setValidated(true);
        setValidatedSecond(true)
        
        if (!hasRoleByRoleType(TeamParticipantEnum.Captain)) {
            alert('В команде должен быть капитан!');
            setIsLoading(false);
            return;
        }
        
        const insufficientRoles = teamStaff.some(staff => {
            const participant = teamParticipants.find(part => part.participantId === staff.playerId);
            if (!participant || participant.participantTypes.length === 0) {
                return true;
            } else if (participant.participantTypes.length === 1) {
                return participant.participantTypes[0] === TeamParticipantEnum.Player;
            }
            return false;
        });
        
        if (insufficientRoles) {
            alert('Не у всех есть роль!');
            setIsLoading(false);
            return;
        }
        
        if (!teamParticipants.some(p => p.participantId === creator.creatorId)) {
            alert('Вас нет в команде!');
            setIsLoading(false);
            return;
        }
        
        const captainWithoutPlayerRole = teamParticipants.some(p => 
            p.participantTypes.includes(TeamParticipantEnum.Captain) && 
            !p.participantTypes.includes(TeamParticipantEnum.Player)
        );
        
        if (captainWithoutPlayerRole) {
            alert('Капитан должен быть игроком!');
            setIsLoading(false);
            return;
        }
        
        const isPlaerWithCoach = teamParticipants.some(p => 
            p.participantTypes.includes(TeamParticipantEnum.Coach) && 
            p.participantTypes.includes(TeamParticipantEnum.Player)
        );
        
        if (isPlaerWithCoach) {
            alert('Тренер не может быть игром!');
            setIsLoading(false);
            return;
        }
        
        try {
            const formData = new FormData();
            formData.append('initiatorId', teamDataUpdate.initiatorId ?? '');
            formData.append('creatorId', teamDataUpdate.creatorId ?? '');
            
            if (teamDataUpdate.logoUrl) {
                formData.append('logoUrl', teamDataUpdate.logoUrl);
            } else {
                formData.append('logoStringUrl', avatars[randomAvatarIdx]);
            }
            
            formData.append('createdBy', String(teamDataUpdate.createdBy));
            formData.append('name', teamDataUpdate.name);
            formData.append('tag', teamDataUpdate.tag || '');
            formData.append('description', teamDataUpdate.description);
            formData.append('creationDate', teamDataUpdate.creationDate);
            formData.append('gameTypeId', teamDataUpdate.gameTypeId || '');
            formData.append('city', teamDataUpdate.city);
            formData.append('score', teamDataUpdate.score.toString());
            formData.append('creationDate', teamDataUpdate.creationDate);
            formData.append('website', teamDataUpdate.website ?? '');
            formData.append('contactEmail', teamDataUpdate.contactEmail ?? '');
            
            formData.append('participantsJSON', JSON.stringify(teamParticipants));
            
            const result = await updateTeam(formData, teamId);
            
            if (result && result != null) {
                setIsLoading(false);
                navigate(`/team/${teamInfo?.teamId}`)
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Create Team error:', error);
        }
    };
    
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            setTeamDataUpdate(prevTeam => ({ ...prevTeam, logoUrl: file }));
            const imageUrl = URL.createObjectURL(e.target.files[0]);
            setCurrentAvatar(imageUrl);
        }
    };
    
    const handleRemoveTeamPlayer = (selectedPlayer: IPlayerSelectorPlayerModel) => {
        if (teamStaff.some(s => s.playerId === selectedPlayer.playerId)) {
            if (selectedPlayer.playerId) {
                handleAddOrRemoveParticipantType(selectedPlayer.playerId, TeamParticipantEnum.Player, false);
                setTeamPlayers(teamPlayers.filter(s => s.playerId !== selectedPlayer.playerId));
            }
        } else {
            setTeamParticipants(teamParticipants.filter(p => p.participantId !== selectedPlayer.playerId));
            setTeamPlayers(teamPlayers.filter(s => s.playerId !== selectedPlayer.playerId));
        }
    };
    
    const { afterToday } = DateRangePicker;
    
    const hasRoleByRoleType = (roleType: TeamParticipantEnum) => {
        return teamParticipants.some(participant =>
            participant.participantTypes.includes(roleType)
        );
    };
    
    const isVisibleCoachChecbox = (member: IPlayerSelectorPlayerModel) => {
        if (teamParticipants.some(p => p.participantId === member.playerId)) {
            if (teamParticipants.find(p => p.participantId === member.playerId)?.participantTypes.includes(TeamParticipantEnum.Player)) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
    
    const handleAddOrRemoveParticipantType = (participantId: string, participantType: TeamParticipantEnum, isChecked: boolean) => {
        setTeamParticipants(prevParticipants => {
            const participantIndex = prevParticipants.findIndex(p => p.participantId === participantId);
            
            if (participantIndex !== -1) {
                const existingParticipant = prevParticipants[participantIndex];
                let updatedParticipantTypes = existingParticipant.participantTypes;
                if (isChecked && !existingParticipant.participantTypes.includes(participantType)) {
                    updatedParticipantTypes = [...existingParticipant.participantTypes, participantType];
                } else if (!isChecked && existingParticipant.participantTypes.includes(participantType)) {
                    updatedParticipantTypes = existingParticipant.participantTypes.filter(type => type !== participantType);
                }
                const updatedParticipant = {
                    ...existingParticipant,
                    participantTypes: updatedParticipantTypes
                };
                setParticipantRoles(prevRoles => ({
                    ...prevRoles,
                    [participantId]: updatedParticipantTypes
                }));
                return [
                    ...prevParticipants.slice(0, participantIndex),
                    updatedParticipant,
                    ...prevParticipants.slice(participantIndex + 1)
                ];
            } else if (isChecked) {
                const newParticipant = { participantId, participantTypes: [participantType] };
                setParticipantRoles(prevRoles => ({
                    ...prevRoles,
                    [participantId]: [participantType]
                }));
                return [
                    ...prevParticipants,
                    newParticipant
                ];
            }
            
            return prevParticipants;
        });
    };
    
    const handleRemoveTeamStaff = (selectedStaff: IPlayerSelectorPlayerModel) => {
        if (selectedStaff.playerId) {
            const isPlayer = teamPlayers.some(s => s.playerId === selectedStaff.playerId);
            
            if (isPlayer) {
                handleAddOrRemoveParticipantType(selectedStaff.playerId, TeamParticipantEnum.Player, false);
                
                setTeamParticipants(prevParticipants => 
                    prevParticipants.map(p => {
                        if (p.participantId === selectedStaff.playerId) {
                            return {
                                ...p,
                                participantTypes: [TeamParticipantEnum.Player]
                            };
                        }
                        return p;
                    })
                );
            } else {
                setTeamParticipants(teamParticipants.filter(p => p.participantId !== selectedStaff.playerId));
            }
            
            setTeamStaff(teamStaff.filter(s => s.playerId !== selectedStaff.playerId));
        }
    };
    
    const isMemberCaptain = (currentMember: IPlayerSelectorPlayerModel) => {
        return teamParticipants.some(participant =>
            participant.participantId === currentMember.playerId &&
            participant.participantTypes.includes(TeamParticipantEnum.Captain)
        );
    };
    
    const popoverPersonal = (
        <Popover >
            <Popover.Body className='infoDescRole'>
                <b>Капитан</b> - Участвует в выборе карт <br />
                <b>Тренер команды</b> - Спектатор матча <br />
                <b>Менеджер</b> - Управлялет командой <br />
                <b>Контент мэйкер</b> - Редактирует новости команды 
                <br /><br />
                <strong>Капитан может быть только один! </strong>
                <strong>Тренер не может быть игроком</strong>
            </Popover.Body>
        </Popover>
    );
    
    const handleAddParticipant = (selectedPlayer: IPlayerSelectorPlayerModel, selectedPlayerRoleType?: TeamParticipantEnum) => {
        const playerId = selectedPlayer.playerId;
        
        if (selectedPlayerRoleType === undefined) {
            if (!teamStaff.some(s => s.playerId === playerId)) {
                if (playerId && playerId === teamDataUpdate.creatorId) {
                    handleAddOrRemoveParticipantType(playerId, TeamParticipantEnum.Creator, true);
                }
                
                setTeamStaff(prev => [
                    ...prev,
                    {
                        playerId: selectedPlayer.playerId,
                        playerName: selectedPlayer.playerName,
                        avatarUrl: selectedPlayer.avatarUrl
                    }
                ]);
                
                return;
            } else {
                return;
            }
        }
        
        if (selectedPlayerRoleType === TeamParticipantEnum.Player) {
            if (playerId !== null && playerId !== undefined) {
                
                if (teamPlayers.length >= 5) {
                    alert('В команде уже 5 игроков!');
                    return;
                }
                
                if (teamPlayers.some(p => p.playerId === playerId)) {
                    return;
                }
                
                if (teamParticipants.some(p => p.participantId === playerId)) {
                    if (teamParticipants.find(p => p.participantId === playerId)?.participantTypes.includes(TeamParticipantEnum.Coach)) {
                        alert('Тренер не может быть игроком!');
                        return;
                    } else {
                        handleAddOrRemoveParticipantType(playerId, TeamParticipantEnum.Player, true);
                    }
                } else {
                    if (playerId && playerId === teamDataUpdate.creatorId) {
                        setTeamParticipants(prev => [
                            ...prev,
                            {
                                participantId: playerId,
                                participantTypes: [selectedPlayerRoleType, TeamParticipantEnum.Creator]
                            }
                        ]);
                    } else {
                        setTeamParticipants(prev => [
                            ...prev,
                            {
                                participantId: playerId,
                                participantTypes: [selectedPlayerRoleType]
                            }
                        ]);
                    }
                }
                
                setTeamPlayers(prev => [
                    ...prev,
                    selectedPlayer
                ]);
            } else {
                return;
            }
        } else {
            if (playerId !== null && playerId !== undefined) {
                if (teamParticipants.some(p => p.participantId === playerId)) {
                    if (selectedPlayerRoleType) {
                        handleAddOrRemoveParticipantType(playerId, selectedPlayerRoleType, true);
                    } else {
                        return
                    }
                } else {
                    if (selectedPlayerRoleType) {
                        setTeamParticipants(prev => [
                            ...prev,
                            {
                                participantId: playerId,
                                participantTypes: [selectedPlayerRoleType]
                            }
                        ]);
                    }
                }
            } else {
                return;
            }
        }
    };
    
    const handleVisibleMemberRoles = (memberId: string) => {
        setVisibleRolesMemberId(prev => (prev === memberId ? null : memberId));
    };
    
    
    const handleSelectfPlayer = (selectedPlayer: IPlayerSelectorPlayerModel) => {
        handleAddParticipant(selectedPlayer, TeamParticipantEnum.Player);
    };
    
    
    const handleSelectfStaff = (selectedPlayer: IPlayerSelectorPlayerModel) => {
        handleAddParticipant(selectedPlayer);
    };
    
    return (
        <Container>
            <div className="create_tournament_container">
                <div className="create_tournament_header">
                    ОБНОВЛЕНИЕ КОМАНДЫ
                    <GooeyButton onClick={handleUpdateTeam} isLoading={isLoading} disabled={isLoading}>
                        ОБНОВИТЬ КОМАНДУ
                    </GooeyButton>
                </div>
                <div className="create_tournament_main_container">
                    <Tab.Container defaultActiveKey="first">
                        <Row>
                            <Col sm={3}>
                                <Nav className="create-tournament-navs" variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" className="create-tournament-navlink">ОПИСАНИЕ</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">СОЦИАЛЬНЫЕ СЕТИ</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">ИГРОКИ КОМАНДЫ</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth">ПЕРСОНАЛ КОМАНДЫ</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form ref={formRef1} className="create_tournament_form" noValidate validated={validated}>
                                            <FloatingLabel label="НАЗВАНИЕ КОМАНДЫ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="name"
                                                    placeholder=""
                                                    value={teamDataUpdate.name}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    maxLength={32}
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="ТЭГ КОМАНДЫ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="tag"
                                                    placeholder=""
                                                    value={teamDataUpdate.tag}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    maxLength={8}
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="ОПИСАНИЕ КОМАНДЫ" className='create_tourn_label'>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={6}
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="description"
                                                    placeholder=""
                                                    value={teamDataUpdate.description}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                />
                                            </FloatingLabel>
                                            <CustomProvider theme="dark" locale={ruRU}>
                                                <DatePicker
                                                    className="create_tournament_form_control"
                                                    format="dd/MM/yyyy"
                                                    placeholder="Дата создания Команды"
                                                    name="creationDate"
                                                    ranges={[]}
                                                    value={teamDataUpdate.creationDate ? new Date(teamDataUpdate.creationDate) : null}
                                                    onChange={(value) => {
                                                        setTeamDataUpdate(prevTeamData => ({
                                                            ...prevTeamData,
                                                            creationDate: value ? value.toISOString() : ''
                                                        }));
                                                    }}
                                                    shouldDisableDate={afterToday()}
                                                />
                                            </CustomProvider>
                                            
                                            <FloatingLabel label="ГОРОД" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="city"
                                                    placeholder=""
                                                    value={teamDataUpdate.city}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                />
                                            </FloatingLabel>
                                            <Form.Select
                                                className="create_tournament_form_control option"
                                                name="gameTypeId"
                                                value={teamDataUpdate.gameTypeId || ''}
                                                required
                                                onChange={handleInputChange}
                                            >
                                                <option disabled value="">ИГРОВАЯ ДИСЦИПЛИНА</option>
                                                <option value={TournamentConstants.CS2.gameTypeId}>CS2</option>
                                                <option value={TournamentConstants.DOTA2.gameTypeId} disabled >DOTA2</option>
                                                <option value="3" disabled >Fortnite</option>
                                            </Form.Select>
                                            <Form.Label>
                                                ЛОГОТИП КОМАНДЫ
                                            </Form.Label>
                                            <div className='createTournament_download_avatar_container'>
                                                <h3 className='createTournament_download_avatar_title'>Загрузите или выберите аватар</h3>
                                                <div className='createTournament_download_avatar'>
                                                    <Form.Group controlId="formFile" className="create_tournament_form_control date">
                                                        <Form.Control
                                                            ref={fileInputRef}
                                                            type="file"
                                                            name="logoUrl"
                                                            onChange={handleFileChange}
                                                        />
                                                    </Form.Group>
                                                    <div className='createTournament_avatar_container'>
                                                        <div className='createTournament_avatar'>
                                                            <img src={currentAvatar} alt="User Avatar" />
                                                            <button className='createTournament_avatar_button' onClick={rotateRight} >
                                                                <ArrowClockwise className='avatar_button_arrowIcon' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Form ref={formRef2} className="create_tournament_form" noValidate validated={validatedSecond}>
                                            <FloatingLabel label="ВЕБСАЙТ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    name="website"
                                                    placeholder=""
                                                    value={teamDataUpdate.website || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="ПОЧТА ДЛЯ СВЯЗИ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    name="contactEmail"
                                                    placeholder=""
                                                    value={teamDataUpdate.contactEmail || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                />
                                            </FloatingLabel>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div className='teammates_block'>
                                            <div className='teammates_block_header'>ИГРОКИ КОМАНДЫ</div>
                                            {teamPlayers.length !== 0 ? (
                                                <div className='teammates__teamcreate_container'>
                                                    {teamPlayers.map((member) => (
                                                        <div key={member.playerId} className='teammates__teamcreate_unit'>
                                                            <X className='team_member_unit_name_x' onClick={() => handleRemoveTeamPlayer(member)} />
                                                            <img className='teammates__teamcreate_avatar' src={`${process.env.REACT_APP_IMG_MINIO_URL}${member.avatarUrl}`} alt="" />
                                                            <a className='teammates__teamcreate_name' target='_blank' href={`/player/${member.playerId}`}>{member.playerName}</a>
                                                            <MyAlert
                                                                onHide={handleCloseMyAlert}
                                                                show={showMyAlert && selectedPlayerId === member.playerId}
                                                                alertText='Вы точно хотите удалить игрока?'
                                                                buttons={[
                                                                    {
                                                                        text: 'Да',
                                                                        onClick: () => {
                                                                            handleCloseMyAlert();
                                                                        }
                                                                    },
                                                                    { text: 'Нет', onClick: handleCloseMyAlert }
                                                                ]}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className='infoText'>В команде нет игроков</div>
                                            )}
                                            
                                            <PlayerSelector onPlayerSelect={handleSelectfPlayer} />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <div className='teammates_block'>
                                            <div className='teammates_block_header'>
                                                ПЕРСОНАЛ КОМАНДЫ
                                                <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popoverPersonal}>
                                                    <InfoCircleFill className='create_tournament_info' />
                                                </OverlayTrigger>
                                            </div>
                                            <div className='teammates__teamcreate_container'>
                                            {teamStaff.length !== 0 ? (
                                                    <div className='teammates__teamcreate_container'>
                                                        {teamStaff.map((member) => (
                                                            <div key={member.playerId} className='teammates__teamcreate_unit'>
                                                                {/* //TODO: в режиме редактирования удаление доступно только капитану */}
                                                                <X className='team_member_unit_name_x' onClick={() => handleRemoveTeamStaff(member)} />
                                                                <img className='teammates__teamcreate_avatar' src={`${process.env.REACT_APP_IMG_MINIO_URL}${member.avatarUrl}`} alt="" />
                                                                <a className='teammates__teamcreate_name' target='_blank' href={`/player/${member.playerId}`}>{member.playerName}</a>
                                                                <div className='rolesList_container'>
                                                                    <button onClick={() => {
                                                                        if (member.playerId) {
                                                                            handleVisibleMemberRoles(member.playerId)
                                                                        }
                                                                    }}>Роли</button>
                                                                    {visibleRolesMemberId === member.playerId && (
                                                                        <Form.Group className="create_tournament_form_checkboxes" controlId="memberRole">
                                                                            {(!hasRoleByRoleType(TeamParticipantEnum.Captain) || isMemberCaptain(member)) && member.playerId && (
                                                                                <>
                                                                                    <Form.Check
                                                                                        type="checkbox"
                                                                                        className='create_tournament_form_checkbox'
                                                                                        label="Капитан"
                                                                                        value={TeamParticipantEnum.Captain}
                                                                                        checked={participantRoles[member.playerId]?.includes(TeamParticipantEnum.Captain) || false}
                                                                                        onChange={(e) => {
                                                                                            if (member.playerId) {
                                                                                                handleAddOrRemoveParticipantType(member.playerId, TeamParticipantEnum.Captain, e.target.checked);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {(member.playerId && (
                                                                                <>
                                                                                {isVisibleCoachChecbox(member) && (
                                                                                    <Form.Check
                                                                                        type="checkbox"
                                                                                        className='create_tournament_form_checkbox'
                                                                                        label="Тренер"
                                                                                        value={TeamParticipantEnum.Coach}
                                                                                        checked={participantRoles[member.playerId]?.includes(TeamParticipantEnum.Coach) || false}
                                                                                        onChange={(e) => {
                                                                                            if (member.playerId) {
                                                                                                handleAddOrRemoveParticipantType(member.playerId, TeamParticipantEnum.Coach, e.target.checked);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                    <Form.Check
                                                                                        disabled
                                                                                        type="checkbox"
                                                                                        className='create_tournament_form_checkbox'
                                                                                        label="Менеджер"
                                                                                        value={TeamParticipantEnum.Manager}
                                                                                        checked={participantRoles[member.playerId]?.includes(TeamParticipantEnum.Manager) || false}
                                                                                        onChange={(e) => {
                                                                                            if (member.playerId) {
                                                                                                handleAddOrRemoveParticipantType(member.playerId, TeamParticipantEnum.Manager, e.target.checked);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <Form.Check
                                                                                        disabled
                                                                                        type="checkbox"
                                                                                        className='create_tournament_form_checkbox'
                                                                                        label="Контент мэйкер"
                                                                                        value={TeamParticipantEnum.ContentMaker}
                                                                                        checked={participantRoles[member.playerId]?.includes(TeamParticipantEnum.ContentMaker) || false}
                                                                                        onChange={(e) => {
                                                                                            if (member.playerId) {
                                                                                                handleAddOrRemoveParticipantType(member.playerId, TeamParticipantEnum.ContentMaker, e.target.checked);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    {partner?.partnerId && (
                                                                                        <Form.Check
                                                                                            type="checkbox"
                                                                                            className='create_tournament_form_checkbox'
                                                                                            label="Владелец"
                                                                                            value={TeamParticipantEnum.Owner}
                                                                                            checked={participantRoles[member.playerId]?.includes(TeamParticipantEnum.Owner) || false}
                                                                                            onChange={(e) => {
                                                                                                if (member.playerId) {
                                                                                                    handleAddOrRemoveParticipantType(member.playerId, TeamParticipantEnum.Owner, e.target.checked);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </>
                                                                            ))}
                                                                        </Form.Group>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className='infoText'>В команде нет персонала</div>
                                                )}
                                            </div>
                                            
                                            <PlayerSelector onPlayerSelect={handleSelectfStaff} />  
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
            <MyAlert
                onHide={handleCloseTeamAlert}
                show={showTeamAlert}
                alertText='Заполните все обязательные поля'
                buttons={[
                    { text: 'Ок', onClick: handleCloseTeamAlert }
                ]}
            />
        </Container>
    );
});

export default UpdateTeam;