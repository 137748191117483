import React, { useEffect, useState } from 'react';
import './CS2WeaponStatistics.css';
import { ICS2WeaponStatistics } from '../../models/dto/stats/cs2/playerStats/ICS2WeaponStatistics';
import CS2WeaponConstans from '../../helpers/constants/CS2WeaponConstans';
import { Funnel, XLg } from 'react-bootstrap-icons';
import { ICS2WeaponView } from '../../models/dto/cs2/ICS2WeaponView';
import CustomSpinner1 from '../CustomSpinners/CustomSpinner1';
import { CS2WeaponsCategoryEnum } from '../../models/enums/cs2/CS2WeaponsCategoryEnum';

interface ICS2WeaponStatisticsProps {
    playerId: string | null;
}

const WeaponStatistics: React.FC<ICS2WeaponStatisticsProps> = ({ playerId }) => {
    const [playerWeaponStatData, setPlayerWeaponStatData] = useState<ICS2WeaponStatistics>();
    const [selectedWeapon, setSelectedWeapon] = useState<ICS2WeaponView>({
        weaponName: CS2WeaponConstans.AK47.weaponName,
        weaponIcon: CS2WeaponConstans.AK47.weaponIcon,
        weaponEnumType: CS2WeaponConstans.AK47.weaponEnumType,
    });
    const [showWeaponFilterModal, setShowWeaponFilterModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedWeaponCategory, setSelectedWeaponCategory] = useState<CS2WeaponsCategoryEnum | null>(null);
    const [activeTab, setActiveTab] = useState<CS2WeaponsCategoryEnum | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 9;
    
    useEffect(() => {
        setIsLoading(true);
        
        // TODO: let data = getPlayerWeaponStat(playerId: string, weaponType: CS2WeaponsEnum);
        setTimeout(() => { // Имитация запроса
            setPlayerWeaponStatData({
                countKills: '1000',
                countHeadshot: '800',
                hs: '80',
                accuracy: '10',
                countDamage: '10000',
            });
            setIsLoading(false);
        }, 2000);
    }, [selectedWeapon]);
    
    let handleSelectedWeapon = (weapon: ICS2WeaponView) => {
        setShowWeaponFilterModal(!showWeaponFilterModal);
        setSelectedWeapon(weapon);
        setCurrentPage(1);
    };
    
    const handleShowWeaponFilterModal = () => {
        setShowWeaponFilterModal(!showWeaponFilterModal);
    };
    
    const filteredWeapons = (selectedWeaponCategory: CS2WeaponsCategoryEnum | null) => {
        if (selectedWeaponCategory === null) {
            return CS2WeaponConstans.Weapons;
        }
        
        const categoryRanges = {
            [CS2WeaponsCategoryEnum.Pistols]: { min: 0, max: 9 },
            [CS2WeaponsCategoryEnum.SubmachineGuns]: { min: 10, max: 16 },
            [CS2WeaponsCategoryEnum.Shotguns]: { min: 17, max: 20 },
            [CS2WeaponsCategoryEnum.HeavyWeapons]: { min: 21, max: 22 },
            [CS2WeaponsCategoryEnum.Rifles]: { min: 23, max: 29 },
            [CS2WeaponsCategoryEnum.SniperRifles]: { min: 30, max: 33 },
            [CS2WeaponsCategoryEnum.Other]: { min: 34, max: 36 },
        };
        
        const range = categoryRanges[selectedWeaponCategory];
        return CS2WeaponConstans.Weapons.filter(w => range && w.weaponEnumType >= range.min && w.weaponEnumType <= range.max);
    };
    
    const weaponsToDisplay = filteredWeapons(selectedWeaponCategory);
    const totalPages = Math.ceil(weaponsToDisplay.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = weaponsToDisplay.slice(startIndex, endIndex);
    
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
    
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    
    return (
        <div className="weaponStatistics_container">
            <div className='weaponStatistics_weaponImgContainer'>
                <div className='weaponStatistics_weaponImg' style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_MINIO_URL + selectedWeapon.weaponIcon})` }}>
                    <div className='weaponStatistics_weaponName'>{selectedWeapon.weaponName}</div>
                    <div className='weaponStatistics_filterIcon' onClick={handleShowWeaponFilterModal}>
                        <Funnel size={20} fontWeight={600} />
                    </div>
                </div>
            </div>
            
            <div className='weaponStatistics_weaponInfo'>
                {isLoading ? (
                    <CustomSpinner1 sizeSpinnerContainer='100px' size='100px' />
                ) : (
                    <>
                        <div className='weaponStatistics_weaponInfoTitles'>
                            <div className='weaponStatistics_weaponInfoTitle'>K</div>
                            <div className='weaponStatistics_weaponInfoTitle'>HS</div>
                            <div className='weaponStatistics_weaponInfoTitle'>% HS</div>
                            <div className='weaponStatistics_weaponInfoTitle'>ТОЧНОСТЬ</div>
                            <div className='weaponStatistics_weaponInfoTitle'>УРОН</div>
                        </div>
                        
                        <div className='weaponStatistics_weaponInfoValues'>
                            <div className='weaponStatistics_weaponInfoValue'>{playerWeaponStatData?.countKills}</div>
                            <div className='weaponStatistics_weaponInfoValue'>{playerWeaponStatData?.countHeadshot}</div>
                            <div className='weaponStatistics_weaponInfoValue'>{playerWeaponStatData?.hs}%</div>
                            <div className='weaponStatistics_weaponInfoValue'>{playerWeaponStatData?.accuracy}%</div>
                            <div className='weaponStatistics_weaponInfoValue'>{playerWeaponStatData?.countDamage}</div>
                        </div>
                    </>
                )}
                
                {showWeaponFilterModal && (
                    <div className='weaponStatistics_filterModal'>
                        <div className='weaponStatistics_filterModal_closeButton' onClick={() => setShowWeaponFilterModal(!showWeaponFilterModal)}>
                            <XLg size={15} />
                        </div>
                        
                        <div className='weaponStatistics_filterModal_tabs'>
                            <div className={`weaponStatistics_filterModal_tab ${activeTab === null ? 'active' : ''}`} onClick={() => { setSelectedWeaponCategory(null); setActiveTab(null); setCurrentPage(1); }}>Все</div>
                            <div className={`weaponStatistics_filterModal_tab ${activeTab === CS2WeaponsCategoryEnum.Pistols ? 'active' : ''}`} onClick={() => { setSelectedWeaponCategory(CS2WeaponsCategoryEnum.Pistols); setActiveTab(CS2WeaponsCategoryEnum.Pistols); setCurrentPage(1); }}>Пистолеты</div>
                            <div className={`weaponStatistics_filterModal_tab ${activeTab === CS2WeaponsCategoryEnum.SubmachineGuns ? 'active' : ''}`} onClick={() => { setSelectedWeaponCategory(CS2WeaponsCategoryEnum.SubmachineGuns); setActiveTab(CS2WeaponsCategoryEnum.SubmachineGuns); setCurrentPage(1); }}>ПП</div>
                            <div className={`weaponStatistics_filterModal_tab ${activeTab === CS2WeaponsCategoryEnum.Shotguns ? 'active' : ''}`} onClick={() => { setSelectedWeaponCategory(CS2WeaponsCategoryEnum.Shotguns); setActiveTab(CS2WeaponsCategoryEnum.Shotguns); setCurrentPage(1); }}>Дробовики</div>
                            <div className={`weaponStatistics_filterModal_tab ${activeTab === CS2WeaponsCategoryEnum.HeavyWeapons ? 'active' : ''}`} onClick={() => { setSelectedWeaponCategory(CS2WeaponsCategoryEnum.HeavyWeapons); setActiveTab(CS2WeaponsCategoryEnum.HeavyWeapons); setCurrentPage(1); }}>Тяжелое</div>
                            <div className={`weaponStatistics_filterModal_tab ${activeTab === CS2WeaponsCategoryEnum.Rifles ? 'active' : ''}`} onClick={() => { setSelectedWeaponCategory(CS2WeaponsCategoryEnum.Rifles); setActiveTab(CS2WeaponsCategoryEnum.Rifles); setCurrentPage(1); }}>Винтовки</div>
                            <div className={`weaponStatistics_filterModal_tab ${activeTab === CS2WeaponsCategoryEnum.SniperRifles ? 'active' : ''}`} onClick={() => { setSelectedWeaponCategory(CS2WeaponsCategoryEnum.SniperRifles); setActiveTab(CS2WeaponsCategoryEnum.SniperRifles); setCurrentPage(1); }}>Снайперские винтовки</div>
                            <div className={`weaponStatistics_filterModal_tab ${activeTab === CS2WeaponsCategoryEnum.Other ? 'active' : ''}`} onClick={() => { setSelectedWeaponCategory(CS2WeaponsCategoryEnum.Other); setActiveTab(CS2WeaponsCategoryEnum.Other); setCurrentPage(1); }}>Разное</div>
                        </div>
                        
                        <div className='weaponStatistics_weaponMapContainer'>
                            <div className='weaponStatistics_weaponMap'>
                                {currentItems.map((weapon, index) => (
                                    <div className='weaponStatistics_weaponImgContainer filter' onClick={() => handleSelectedWeapon(weapon)} key={index}>
                                        <div className='weaponStatistics_weaponImg filter' style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_MINIO_URL + weapon.weaponIcon})` }}>
                                            <div className='weaponStatistics_weaponName filter'>{weapon.weaponName}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            
                            {pageNumbers.length > 1 && (
                                <div className="weaponStatistics_pagination">
                                    {pageNumbers.map((pageNumber) => (
                                        <button 
                                            key={pageNumber} 
                                            onClick={() => handlePageChange(pageNumber)}
                                            className={pageNumber === currentPage ? 'active' : ''}
                                        >
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WeaponStatistics;