import { FloatingLabel, Form, Tab } from "react-bootstrap";
import { IDota2CreateTournamentViewModel } from "../../viewModels/tournament/Dota2CreateTournamentViewModel";

interface DotaSettingsFormProps {
    dota2Tournament: IDota2CreateTournamentViewModel;
    handleDota2TournamentVMChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Dota2TabCreateTournament: React.FC<DotaSettingsFormProps> = ({ dota2Tournament, handleDota2TournamentVMChange }) => {
    return (
        <Tab.Pane eventKey="fourth">
            <Form className="create_tournament_form">
                <FloatingLabel label="ВИДИМОСТЬ ЛОББИ" className='create_tourn_label'>
                    <Form.Select
                        className="create_tournament_form_control option"
                        name="pauseQuantity"
                        value={dota2Tournament.lobbyVisibility || ''}
                        required
                        onChange={handleDota2TournamentVMChange}
                    >
                        <option value={1}>Для всех</option>
                        <option value={2}>Только для друзей</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel label="ИГРОВОЙ РЕЖИМ" className='create_tourn_label'>
                    <Form.Select
                        className="create_tournament_form_control option"
                        name="freezeTime"
                        onChange={handleDota2TournamentVMChange}
                        value={dota2Tournament.gameMode || ''}
                    >
                        <option value={5}>Capitains Mode</option>
                        <option value={10}>All Pick</option>
                        <option value={15}>MID 1vs1</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel label="РЕЖИМ ИГРОВЫХ ПАУЗ" className='create_tourn_label'>
                    <Form.Select
                        className="create_tournament_form_control option"
                        name="pauseQuantity"
                        value={dota2Tournament.lobbyPauseSettings || ''}
                        required
                        onChange={handleDota2TournamentVMChange}
                    >
                        <option value={1}>Без ограничений</option>
                        <option value={2}>Лимитированы</option>
                        <option value={3}>Запрещены</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel label="ЗАДЕРЖКА DotaTV (сек.)" className='create_tourn_label'>
                    <Form.Select
                        className="create_tournament_form_control option"
                        name="pauseQuantity"
                        value={dota2Tournament.lobbyDotaTVDelay || ''}
                        required
                        onChange={handleDota2TournamentVMChange}
                    >
                        <option value={1}>10</option>
                        <option value={2}>120</option>
                        <option value={3}>300</option>
                        <option value={3}>900</option>
                    </Form.Select>
                </FloatingLabel>
            </Form>
        </Tab.Pane>
    );
};

export default Dota2TabCreateTournament;