import React, { useContext, useEffect, useRef, useState } from 'react';
import './CreateTournament.css';
import { Col, Container, FloatingLabel, Form, Nav, OverlayTrigger, Popover, Row, Tab } from 'react-bootstrap';
import { ICreateTournamentViewModel } from '../../viewModels/tournament/ICreateTournamentViewModel';
import { TournamentConstants } from '../../helpers/constants/tournamentConstants';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { AppContext } from '../../components/AppContext';
import { createCS2Tournament, getAllMaps } from '../../http/tournament/cs2TournamentActionsAPI';
import { TournamentTypeEnum } from '../../models/enums/tournament/TournamentTypeEnum';
import { MatchBracketTypeEnum } from '../../models/enums/tournament/MatchBracketTypeEnum';
import { FinalStageTypeEnum } from '../../models/enums/tournament/FinalStageTypeEnum';
import { MatchesFormatEnum } from '../../models/enums/tournament/MatchesFormatEnum';
import { ICS2MapModel } from '../../models/dto/cs2/ICS2MapModel';
import { ArrowClockwise, InfoCircleFill, PlusCircle, Search, X } from 'react-bootstrap-icons';
import MyAlert from '../../components/MyAlert/MyAlert';
import { ICS2CreateTournamentViewModel } from '../../viewModels/tournament/CS2CreateTournamentViewModel';
import { DateRangePicker, DatePicker, CustomProvider } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { ruRU } from 'rsuite/esm/locales';
import { getPlayersByNicknamePart } from '../../http/playerActionsAPI';
import { IPlayerSelectorPlayerModel } from '../../components/PlayerSelector/Models/IPlayerSelectorPlayerModel';
import { useModal } from '../../hooks/useModal';
import { SystemRolesEnum } from '../../models/enums/SystemRolesEnum';
import GooeyButton from '../../components/Buttons/GooeyButton/GooeyButton';
import { IDota2CreateTournamentViewModel } from '../../viewModels/tournament/Dota2CreateTournamentViewModel';
import Dota2TabCreateTournament from '../../components/GameTabsCreateTournament/Dota2TabCreateTournament';
import CS2TabCreateTournament from '../../components/GameTabsCreateTournament/CS2TabCreateTournament';
import { calculateThirdPlaceDate } from '../../http/tournament/tournamentActionsAPI';
import { createDota2Tournament } from '../../http/tournament/dota2TournamentActions';

const avatars = [
    'tournament_avatar1.jpg',
    'tournament_avatar2.jpg',
    'tournament_avatar3.jpg'
];

const CreateTournament: React.FC = observer(() => {
    const { player } = useContext(AppContext);
    const [validated, setValidated] = useState(false);
    const [isRoundSystemChecked, setIsRoundSystemChecked] = useState(false);
    const [isStandardSystemChecked, setIsStandardSystemChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMaps, setSelectedMaps] = useState<string[]>([]);
    const [allMaps, setAllMaps] = useState<ICS2MapModel[]>([]);
    const [showCompetitive, setShowCompetitive] = useState(false);

    const [selectedTournamentTypeEnumBottom, setSelectedTournamentTypeEnumBottom] = useState(false);
    const [randomAvatarIdx, setRandomAvatarIdx] = useState(0);
    const [currentAvatar, setCurrentAvatar] = useState<string>(`${process.env.REACT_APP_IMG_MINIO_URL}${avatars[randomAvatarIdx]}`);
    const { beforeToday } = DateRangePicker;
    const navigate = useNavigate();
    const [alertText, setAlertText] = useState('');
    const [groupOptions, setGroupOptions] = useState<number[]>([2, 4]);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);
    const [minDateForThirdPlaceMatch, setMinDateForThirdPlaceMatch] = useState<Date | null>(null);
    const maxDateForThirdPlaceMatch = minDateForThirdPlaceMatch ? new Date(minDateForThirdPlaceMatch.getTime() + 14 * 24 * 60 * 60 * 1000) : null;
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState<IPlayerSelectorPlayerModel[] | null>([]);
    const [additionalMembers, setAdditionalMembers] = useState<IPlayerSelectorPlayerModel[]>([]);
    const [memberRoles, setMemberRoles] = useState<{ playerId: string, role: string }[]>([]);
    const { isVisible: showMyAlert, openModal: handleShowMyAlert, closeModal: handleCloseMyAlert } = useModal();
    const { isVisible: showCreateAlert, openModal: handleShowCreateAlert, closeModal: handleCloseCreateAlert } = useModal();
    const [tournamentAdmins, setTournamentAdmins] = useState<string[]>([]);
    const [tournamentStreamers, setTournamentStreamers] = useState<string[]>([]);
    const [tournamentObservers, setTournamentObservers] = useState<string[]>([]);
    const [selectedPlayerId, setSelectedPlayerId] = useState('');

    const filteredMaps = showCompetitive
        ? allMaps.filter((map) => map.isCompetitive)
        : allMaps;

    const [cs2Tournament, setCs2Tournament] = useState<ICS2CreateTournamentViewModel>({
        mapBanSeconds: 15,
        cs2MapGuids: selectedMaps,
        pauseDuration: 30,
        pauseQuantity: 1,
        freezeTime: 5
    });
    const [dota2Tournament, setDota2Tournament] = useState<IDota2CreateTournamentViewModel>({
        allowSpectating: true,
        gameMode: 1,
        lobbyPauseSettings: 0,
        lobbyDotaTVDelay: 0,
        lobbyVisibility: 0,
    });

    const [tournament, setTournament] = useState<ICreateTournamentViewModel>({
        organizedBy: SystemRolesEnum.Player, // TODO Partner
        playerOrganizatorId: player?.playerId,
        partnerOrganizatorId: '', // TODO Partner
        name: '',
        gameTypeId: TournamentConstants.CS2.gameTypeId,
        description: '',
        location: '',
        prizePool: null,
        logoFile: undefined,
        logoStringUrl: undefined,
        materialPrizes: null,
        maxSlots: 8,
        tournamentTypeEnum: TournamentTypeEnum.OnlySingle,
        upperMatchBracketTypeEnum: MatchBracketTypeEnum.BO1,
        matchesFormatEnum: isStandardSystemChecked ? MatchesFormatEnum.VS5X5 : 0,
        isThirdPlaceEnabled: false,
        thirdPlaceDate: '',
        firstMatchDate: '',
        parallelMatchesCount: 1,
        startMatchHour: 10,
        endMatchHour: 20,
        regDateStart: '',
        regDateEnd: '',
        firstPlaceDate: '',
        countTeamInGroup: 2,
        fastCupFlag: false,
        parallelFinals: false,
        // staff
        tournamentStreamers: [],
        tournamentPlayerAdmins: [],
        tournamentObservers: [],
        lobbyStaffWaiting: false,
        additionalSlots: -1,
        coachesAllowedInMatches: false,

        // cs2
        cS2TournamentVM: cs2Tournament,
        dota2TournamentVM: dota2Tournament,
    });

    const popover = (
        <Popover >
            <Popover.Header as="h6">Обратите внимание</Popover.Header>
            <Popover.Body>
                При выборе дат учитывается Ваш <strong>текущий часовой пояс</strong>. Не используйте VPN.
            </Popover.Body>
        </Popover>
    );
    const popoverFastCup = (
        <Popover >
            <Popover.Body>
                Быстрый турнир, максимум 16 команд. Матчи следуют друг за другом, в расписании время матчей будет указано приблизительно.
            </Popover.Body>
        </Popover>
    );
    const popoverPersonal = (
        <Popover >
            <Popover.Header as="h6">Назначение стафа на турнир</Popover.Header>
            <Popover.Body>
                <strong>Зависит от выбранного количества доп слотов! </strong> <br />
                Админ - управление игровым сервером.<br />
                Стример - трансляция игры на внешних платформах. <br />
                Обсервер - мониторинг внутри игры.
            </Popover.Body>
        </Popover>
    );

    const rotateRight = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setRandomAvatarIdx((prev) => (prev + 1) % avatars.length);
        setCurrentAvatar(`${process.env.REACT_APP_IMG_MINIO_URL}${avatars[randomAvatarIdx]}`);

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            setTournament(prev => ({ ...prev, logoFile: undefined }))
        }
    };

    const handleMapClick = (mapId: string) => {
        setSelectedMaps((prevSelectedMaps) => {
            const newSelectedMaps = prevSelectedMaps.includes(mapId)
                ? prevSelectedMaps.filter((selectedId) => selectedId !== mapId)
                : [...prevSelectedMaps, mapId];

            setCs2Tournament((prevCs2Tournament) => ({
                ...prevCs2Tournament,
                mapIds: newSelectedMaps,
            }));
            return newSelectedMaps;
        });
    };

    const handleCheckboxChange = () => {
        setShowCompetitive((prevShowCompetitive) => !prevShowCompetitive);
    };

    const updateGroupOptions = (maxSlots: number) => {
        const options = [];
        for (let i = 2; i <= maxSlots / 2; i++) {
            if (maxSlots % i === 0) {
                options.push(i);
            }
        }
        setGroupOptions(options);
        setTournament(prev => ({ ...prev, countTeamInGroup: 0 }));
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | null,
        customName?: string
    ) => {
        if (!e) return;

        const { name: targetName, value, type } = e.target;
        const name = customName || targetName;

        if (name === 'maxSlots') {
            updateGroupOptions(parseInt(value));
        }

        if (type === 'checkbox') {
            const isChecked = (e.target as HTMLInputElement).checked;
            setTournament(prevTournament => ({ ...prevTournament, [name]: isChecked }));
        } else {
            setTournament(prevTournament => ({
                ...prevTournament,
                [name]: value
            }));
        }
    };

    const handleCS2TournamentVMChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        const parsedValue = isNaN(Number(value)) ? value : Number(value);

        setCs2Tournament(prevTournament => {
            const updatedTournament = {
                ...prevTournament,
                [name]: parsedValue,
            };
            setTournament(prev => ({
                ...prev,
                cS2TournamentVM: updatedTournament,
            }));

            return updatedTournament;
        });
    };
    const handleDota2TournamentVMChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        const parsedValue = isNaN(Number(value)) ? value : Number(value);

        setDota2Tournament(prevTournament => {
            const updatedTournament = {
                ...prevTournament,
                [name]: parsedValue,
            };
            setTournament(prev => ({
                ...prev,
                dota2TournamentVM: updatedTournament,
            }));

            return updatedTournament;
        });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
                setTournament(prevTournament => ({ ...prevTournament, logoFile: file }));
                const imageUrl = URL.createObjectURL(file);
                setCurrentAvatar(imageUrl);
            } else {
                alert('Пожалуйста, выберите файл изображения (PNG или JPEG).');
            }
        }
    };

    const adjustHoursForTimezone = (hour: number) => {
        const currentDate = new Date();
        const timezoneOffsetHours = currentDate.getTimezoneOffset() / 60;
        return hour + timezoneOffsetHours;
    };

    const handleCreateTournament = async (e: React.FormEvent) => {
        e.preventDefault();
        setValidated(true);
        setIsLoading(true);
        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity() === false) {
            setIsLoading(false);
            e.stopPropagation();
            return;
        }

        const rolesNotSelected = additionalMembers.some(member =>
            !memberRoles.some(role => role.playerId === member.playerId)
        );

        if (rolesNotSelected) {
            setIsLoading(false);
            setAlertText("Заполните роли для персонала")
            handleShowCreateAlert();
            return;
        }
        if (!tournament.firstMatchDate || isNaN(Date.parse(tournament.firstMatchDate))) {
            setIsLoading(false);
            setAlertText("Укажите дату старта матчей");
            handleShowCreateAlert();
            return;
        }
        if (tournament.cS2TournamentVM) {
            tournament.cS2TournamentVM.cs2MapGuids = selectedMaps;
        }

        const formData = new FormData();
        if (tournament.logoFile) {
            formData.append('logoFile', tournament.logoFile);
        } else {
            formData.append('logoStringUrl', avatars[randomAvatarIdx]);
        }
        formData.append('playerOrganizatorId', tournament.playerOrganizatorId || '');
        formData.append('partnerOrganizatorId', tournament.partnerOrganizatorId || '');
        formData.append('organizedBy', String(tournament.organizedBy) || '');
        formData.append('name', tournament.name);
        formData.append('gameTypeId', tournament.gameTypeId || '');
        formData.append('description', tournament.description);
        formData.append('location', tournament.location);
        formData.append('prizePool', tournament.prizePool?.toString() || '');
        formData.append('materialPrizes', tournament.materialPrizes || '');
        formData.append('maxSlots', tournament.maxSlots.toString());
        formData.append('tournamentTypeEnum', tournament.tournamentTypeEnum?.toString() || '');
        formData.append('upperMatchBracketTypeEnum', tournament.upperMatchBracketTypeEnum.toString());
        formData.append('lowerMatchBracketTypeEnum', tournament.lowerMatchBracketTypeEnum?.toString() || '');

        formData.append('finalStageTypeEnum', tournament.finalStageTypeEnum?.toString() || '');
        formData.append('matchesFormatEnum', tournament.matchesFormatEnum.toString());
        formData.append('isThirdPlaceEnabled', tournament.isThirdPlaceEnabled ? 'true' : 'false');
        formData.append('thirdPlaceTypeEnum', tournament.thirdPlaceTypeEnum?.toString() || '');
        formData.append('firstMatchDate', tournament.firstMatchDate);
        formData.append('parallelMatchesCount', tournament.parallelMatchesCount.toString());
        formData.append('startMatchHour', adjustHoursForTimezone(tournament.startMatchHour).toString());
        formData.append('endMatchHour', adjustHoursForTimezone(tournament.endMatchHour).toString());
        formData.append('regDateStart', tournament.regDateStart);
        formData.append('regDateEnd', tournament.regDateEnd);
        formData.append('firstPlaceDate', tournament.firstPlaceDate);
        formData.append('fastCupFlag', tournament.fastCupFlag ? 'true' : 'false');
        formData.append('parallelFinals', tournament.parallelFinals ? 'true' : 'false');

        tournamentAdmins.forEach((admin, index) => {
            formData.append(`tournamentPlayerAdmins[${index}]`, admin);
        });
        tournamentStreamers.forEach((streamer, index) => {
            formData.append(`tournamentStreamers[${index}]`, streamer);
        });
        tournamentObservers.forEach((observer, index) => {
            formData.append(`tournamentObservers[${index}]`, observer);
        });
        formData.append('lobbyStaffWaiting', tournament.lobbyStaffWaiting ? 'true' : 'false');
        const totalAdditionalSlots = tournament.additionalSlots === -1 ? 0 : tournament.additionalSlots;
        formData.append('additionalSlots', totalAdditionalSlots?.toString() || '');
        formData.append('coachesAllowedInMatches', tournament.coachesAllowedInMatches ? 'true' : 'false');
        if (tournament.isThirdPlaceEnabled) {
            formData.append('thirdPlaceDate', tournament.thirdPlaceDate || '');
        }

        switch (tournament.gameTypeId) {
            case TournamentConstants.CS2.gameTypeId:
                formData.append('cS2TournamentVM.mapBanSeconds', tournament.cS2TournamentVM?.mapBanSeconds?.toString() || '');
                tournament.cS2TournamentVM?.cs2MapGuids?.forEach((mapId, index) => {
                    formData.append(`cS2TournamentVM.mapIds[${index}]`, mapId);
                });
                formData.append('cS2TournamentVM.pauseDuration', tournament.cS2TournamentVM?.pauseDuration?.toString() || '');
                formData.append('cS2TournamentVM.pauseQuantity', tournament.cS2TournamentVM?.pauseQuantity?.toString() || '');
                formData.append('cS2TournamentVM.freezeTime', tournament.cS2TournamentVM?.freezeTime?.toString() || '');
                createCS2Tournament(formData)
                    .then((tournamentId) => {
                        setIsLoading(false);
                        if (tournamentId) {
                            navigate(`/tournament/${tournamentId}`);
                        } else {
                            setAlertText("Ошибка при создании турнира. Заполните обязательные поля");
                            handleShowCreateAlert();
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setAlertText("Ошибка при создании турнира");
                        handleShowCreateAlert();
                    });
                break;
            case TournamentConstants.DOTA2.gameTypeId:
                formData.append('dota2TournamentVM.lobbyVisibility', tournament.dota2TournamentVM?.lobbyVisibility?.toString() || '');
                formData.append('dota2TournamentVM.gameMode', tournament.dota2TournamentVM?.gameMode?.toString() || '');
                formData.append('dota2TournamentVM.lobbyPauseSettings', tournament.dota2TournamentVM?.lobbyPauseSettings?.toString() || '');
                formData.append('dota2TournamentVM.lobbyDotaTVDelay', tournament.dota2TournamentVM?.lobbyDotaTVDelay?.toString() || '');
                formData.append('dota2TournamentVM.allowSpectating', tournament.dota2TournamentVM?.allowSpectating?.toString() || '');
                createDota2Tournament(formData)
                    .then((tournamentId) => {
                        setIsLoading(false);
                        if (tournamentId) {
                            navigate(`/tournament/${tournamentId}`);
                        } else {
                            setAlertText("Ошибка при создании турнира. Заполните обязательные поля");
                            handleShowCreateAlert();
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setAlertText("Ошибка при создании турнира");
                        handleShowCreateAlert();
                    });
                break;
        }
    };

    const handleTimeChange = (value: any, field: string) => {
        if (value) {
            const hours = value.getHours();
            setTournament(prev => ({
                ...prev,
                [field]: hours
            }));
        } else {
            setTournament(prev => ({
                ...prev,
                [field]: ''
            }));
        }
    };

    const handleRangeChange = (range: [Date, Date] | null) => {
        if (!range) {
            setDateRange(null);
            setTournament(prev => ({
                ...prev,
                regDateStart: '',
                regDateEnd: ''
            }));
        } else {
            const [start, end] = range;
            setDateRange([start, end]);
            setTournament(prev => ({
                ...prev,
                regDateStart: start.toISOString(),
                regDateEnd: end.toISOString()
            }));
        }
    };

    const handleFirstMatchDateChange = (value: Date | null) => {
        if (!value) {
            setTournament(prev => ({ ...prev, firstMatchDate: '' }));
            return;
        }
    
        value.setMinutes(0, 0, 0);
    
        const regDateEnd = tournament.regDateEnd ? new Date(tournament.regDateEnd) : new Date();
        const selectedDate = new Date(value.getFullYear(), value.getMonth(), value.getDate());
    
        if (selectedDate.getTime() === new Date(regDateEnd.getFullYear(), regDateEnd.getMonth(), regDateEnd.getDate()).getTime()) {
            const regEndHour = regDateEnd.getHours();
            const regEndMinutes = regDateEnd.getMinutes();
    
            if (value.getHours() < regEndHour || (value.getHours() === regEndHour && value.getMinutes() <= regEndMinutes)) {
                value.setHours(regEndHour + 1, 0, 0, 0);
            }
        }
    
        const startMatchHour = tournament.startMatchHour;
        const endMatchHour = tournament.endMatchHour;
    
        if (startMatchHour < endMatchHour) {
            if (value.getHours() < startMatchHour || value.getHours() > endMatchHour) {
                return;
            }
        } else {
            if (!(value.getHours() >= startMatchHour || value.getHours() < endMatchHour)) {
                return;
            }
        }
    
        setTournament(prev => ({ ...prev, firstMatchDate: value.toISOString() }));
    };
    

    const handleThirdPlaceDateChange = (value: Date | null) => {
        if (!value) {
            setTournament(prev => ({ ...prev, thirdPlaceDate: '' }));
            return;
        }
        const selectedDate = new Date(value.getFullYear(), value.getMonth(), value.getDate());

        if ((minDateForThirdPlaceMatch && selectedDate < minDateForThirdPlaceMatch) || (maxDateForThirdPlaceMatch && selectedDate > maxDateForThirdPlaceMatch)) {
            return;
        }
        setTournament(prev => ({ ...prev, thirdPlaceDate: value.toISOString() }));
    };

    const handleFirstPlaceDateChange = (value: Date | null) => {
        if (!value) {
            setTournament(prev => ({ ...prev, firstPlaceDate: '' }));
            return;
        }
        const selectedDate = new Date(value.getFullYear(), value.getMonth(), value.getDate());
    
        const minDateForFinal = new Date(tournament.firstPlaceDate);
        if (selectedDate < minDateForFinal || (maxDateForThirdPlaceMatch && selectedDate > maxDateForThirdPlaceMatch)) {
            return;
        }
        setTournament(prev => ({ ...prev, firstPlaceDate: value.toISOString() }));
    };
        


    const handleInputSearch = (e: any) => {
        let value: string = e.target.value as string;
        if (value.length >= 3) { setSearchValue(value); }
    };
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSearchSubmit(e);
        }
    };
    const handleSearchSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const results = await getPlayersByNicknamePart(searchValue);
        setSearchResults(results);
    };
    const addAdditionalMembers = (selectedPlayer: IPlayerSelectorPlayerModel) => {
        if (tournament.additionalSlots) {
            if (additionalMembers.length >= tournament.additionalSlots) { return }
        } else {
            return;
        }
        setAdditionalMembers(prev => {
            const isPlayerAlreadyAdded = prev.some(player => player.playerId === selectedPlayer.playerId);

            if (isPlayerAlreadyAdded) {
                return prev;
            }

            return [...prev, selectedPlayer];
        });
    }
    const removeSelectedMember = (selectedPlayerModel: IPlayerSelectorPlayerModel) => {
        setAdditionalMembers(prevMembers => prevMembers.filter(member => member.playerId !== selectedPlayerModel.playerId));
    }
    const addMemberByRole = (selectedRole: string, selectedMemberId: string) => {
        const removePlayerFromCurrentRole = (playersArray: string[]) => {
            return playersArray.filter(player => player !== selectedMemberId);
        };

        setTournamentAdmins(currentAdmins => removePlayerFromCurrentRole(currentAdmins));
        setTournamentStreamers(currentStreamers => removePlayerFromCurrentRole(currentStreamers));
        setTournamentObservers(currentObservers => removePlayerFromCurrentRole(currentObservers));

        switch (selectedRole) {
            case 'Admin':
                setTournamentAdmins(currentAdmins => [...currentAdmins, selectedMemberId]);
                break;

            case 'Streamer':
                setTournamentStreamers(currentStreamers => [...currentStreamers, selectedMemberId]);
                break;

            case 'Observer':
                setTournamentObservers(currentObservers => [...currentObservers, selectedMemberId]);
                break;

            default:
                break;
        }

        setMemberRoles(prevRoles => {
            const updatedRoles = prevRoles.filter(member => member.playerId !== selectedMemberId);
            if (selectedRole) {
                updatedRoles.push({ playerId: selectedMemberId, role: selectedRole });
            }
            return updatedRoles;
        });
    };
    const handleShowMyAlertForMember = (playerId: string) => {
        setSelectedPlayerId(playerId);
        handleShowMyAlert();
    }

    const handleRoundSystemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setIsRoundSystemChecked(isChecked);
        if (isChecked) {
            setIsStandardSystemChecked(false);
            setTournament({
                ...tournament,
                tournamentTypeEnum: TournamentTypeEnum.OnlyRobin,
                lowerMatchBracketTypeEnum: null,
            });
            setSelectedTournamentTypeEnumBottom(false);
        } else {
            setTournament({
                ...tournament,
                tournamentTypeEnum: null,
                lowerMatchBracketTypeEnum: null,
            });
        }
    };

    useEffect(() => {
        setCurrentAvatar(`${process.env.REACT_APP_IMG_MINIO_URL}${avatars[randomAvatarIdx]}`);
    }, [randomAvatarIdx]);

    useEffect(() => {
        if (tournament?.additionalSlots && tournament?.additionalSlots <= 0) {
            setAdditionalMembers([]);
        } else if (tournament?.additionalSlots && additionalMembers.length > tournament?.additionalSlots) {
            setAdditionalMembers(additionalMembers.slice(0, tournament?.additionalSlots));
        }
    }, [tournament?.additionalSlots]);

    useEffect(() => {
        let result = getAllMaps()
            .then((r) => {
                if (!result) {
                    console.log(`Cannot get cs2 maps!`);
                } else {
                    setAllMaps(r as ICS2MapModel[]);
                }
            })
            .catch((r) => {
                console.log(`Getting map error: ${r}`);
            });
    }, [])

    useEffect(() => {
        if (tournament.tournamentTypeEnum === TournamentTypeEnum.OnlyRobin) {
            setTournament(prev => ({ ...prev, isThirdPlaceEnabled: false }));
        } else if (tournament.tournamentTypeEnum === TournamentTypeEnum.OnlyDouble || tournament.tournamentTypeEnum === TournamentTypeEnum.RobinWithDouble) {
            setTournament(prev => ({ ...prev, isThirdPlaceEnabled: true }));
        }
    }, [tournament.tournamentTypeEnum]);

    useEffect(() => {
        setTournament(prev => ({
            ...prev,
            finalStageTypeEnum: isStandardSystemChecked ? FinalStageTypeEnum.BO1 : null,
            matchesFormatEnum: isStandardSystemChecked ? MatchesFormatEnum.VS5X5 : 0,
            lowerMatchBracketTypeEnum: selectedTournamentTypeEnumBottom ? MatchBracketTypeEnum.BO1 : null,
            thirdPlaceTypeEnum: selectedTournamentTypeEnumBottom ? FinalStageTypeEnum.BO1 : null
        }));
    }, [isStandardSystemChecked, selectedTournamentTypeEnumBottom]);

    useEffect(() => {
        if (tournament.firstMatchDate && !tournament.fastCupFlag) {
            const calculateData = {
                teamsCount: tournament.maxSlots,
                startMatchesDate: tournament.firstMatchDate,
                startMatchHour: adjustHoursForTimezone(tournament.startMatchHour),
                endMatchHour: adjustHoursForTimezone(tournament.endMatchHour),
                upperTypeMatch: tournament.upperMatchBracketTypeEnum,
                lowerTypeMatch: tournament.lowerMatchBracketTypeEnum,
                parallelMatches: tournament.parallelMatchesCount
            }
            calculateThirdPlaceDate(calculateData)
                .then(baseDate => {
                    if (baseDate) {
                        let adjustedDate = new Date(baseDate);
                        
                        let minDateForFinal = new Date(adjustedDate);
                        if (!tournament.parallelFinals && tournament.isThirdPlaceEnabled && tournament.thirdPlaceTypeEnum !== undefined && tournament.thirdPlaceTypeEnum !== null) {
                            const additionalHours: any = { 0: 1, 1: 3, 2: 5 };
                            const hoursToAdd = additionalHours[tournament.thirdPlaceTypeEnum] || 0;
                            minDateForFinal.setHours(minDateForFinal.getHours() + hoursToAdd);
                            setTournament(prev => ({
                                ...prev,
                                thirdPlaceDate: adjustedDate.toISOString(),
                                firstPlaceDate: minDateForFinal.toISOString()
                            }));
                        } else {
                            if (tournament.parallelFinals) {
                                setTournament(prev => ({
                                    ...prev,
                                    thirdPlaceDate: adjustedDate.toISOString()
                                }));
                            }
                            setTournament(prev => ({
                                ...prev,
                                firstPlaceDate: adjustedDate.toISOString()
                            }));
                        }
                        setMinDateForThirdPlaceMatch(adjustedDate);
                    }
                })
                .catch(error => {
                    console.error('Error fetching min date for match:', error);
                });
        } else if (tournament.fastCupFlag) {
            setTournament(prev => ({
                ...prev,
                thirdPlaceDate: '',
                firstPlaceDate: '',
                regDateEnd: '',
                regDateStart: ''
            }));
        }
    }, [
        tournament.firstMatchDate,
        tournament.maxSlots,
        tournament.startMatchHour,
        tournament.endMatchHour,
        tournament.upperMatchBracketTypeEnum,
        tournament.lowerMatchBracketTypeEnum,
        tournament.parallelMatchesCount,
        tournament.isThirdPlaceEnabled,
        tournament.thirdPlaceTypeEnum,
        tournament.fastCupFlag,
        tournament.parallelFinals
    ]);

    useEffect(() => {
        if (tournament.parallelFinals && tournament.thirdPlaceDate) {
            setTournament(prev => ({
                ...prev,
                firstPlaceDate: tournament.thirdPlaceDate || ''
            }));
        }
    }, [tournament.parallelFinals, tournament.thirdPlaceDate]);

    useEffect(() => {
        if (!tournament.isThirdPlaceEnabled && tournament.parallelFinals) {
            setTournament(prev => ({
                ...prev,
                parallelFinals: false
            }));
        }
    }, [tournament.isThirdPlaceEnabled, tournament.parallelFinals]);

    useEffect(() => {
        if (tournament.fastCupFlag) {
            setTournament(prev => ({
                ...prev,
                firstPlaceDate: '',
                thirdPlaceDate: ''
            }));
        }
    }, [tournament.fastCupFlag]);


    return (
        <Container>
            <div className="create_tournament_container">
                <div className="create_tournament_header">
                    СОЗДАНИЕ ТУРНИРА
                    <GooeyButton onClick={handleCreateTournament} isLoading={isLoading} disabled={isLoading}>
                        СОЗДАТЬ ТУРНИР
                    </GooeyButton>
                </div>
                <MyAlert
                    show={showCreateAlert}
                    onHide={handleCloseCreateAlert}
                    alertText={alertText}
                    buttons={[
                        { text: 'РЕДАКТИРОВАТЬ', onClick: handleCloseCreateAlert }
                    ]}
                />
                <div className="create_tournament_main_container">
                    <Tab.Container defaultActiveKey="first">
                        <Row>
                            <Col sm={3}>
                                <Nav className="create-tournament-navs" variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" className="create-tournament-navlink">ОСНОВНОЕ</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">ИГРОВЫЕ НАСТРОЙКИ</Nav.Link>
                                    </Nav.Item>
                                    {(isStandardSystemChecked || isRoundSystemChecked) && (
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">НАСТРОЙКИ ВРЕМЕНИ</Nav.Link>
                                        </Nav.Item>
                                    )}
                                    <Nav.Link eventKey="fourth">
                                        {tournament.gameTypeId === TournamentConstants.CS2.gameTypeId
                                            ? 'CS 2'
                                            : tournament.gameTypeId === TournamentConstants.DOTA2.gameTypeId
                                                ? 'DOTA 2'
                                                : ''}
                                    </Nav.Link>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fifth" className="create-tournament-navlink">ДОП СЛОТЫ</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form className="create_tournament_form" noValidate validated={validated}>
                                            <FloatingLabel label="ИГРОВАЯ ДИСЦИПЛИНА" className='create_tourn_label'>
                                                <Form.Select
                                                    className="create_tournament_form_control option"
                                                    name="gameTypeId"
                                                    value={tournament.gameTypeId || ''}
                                                    required
                                                    onChange={handleInputChange}
                                                >
                                                    <option value={TournamentConstants.CS2.gameTypeId}>CS2</option>
                                                    <option value={TournamentConstants.DOTA2.gameTypeId}>DOTA2</option>
                                                    <option value="3" disabled >Fortnite</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                            <FloatingLabel label="НАЗВАНИЕ ТУРНИРА" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="name"
                                                    placeholder=""
                                                    value={tournament.name || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    maxLength={50}
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="ОПИСАНИЕ ТУРНИРА" className='create_tourn_label'>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={6}
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="description"
                                                    placeholder=""
                                                    value={tournament.description || ''}
                                                    onChange={handleInputChange}
                                                    maxLength={350}
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="МЕСТО ПРОВЕДЕНИЯ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="location"
                                                    placeholder=""
                                                    value={tournament.location || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="ПРИЗОВОЙ ФОНД" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    name="prizePool"
                                                    type="number"
                                                    placeholder=""
                                                    value={tournament.prizePool || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                />
                                            </FloatingLabel>
                                            <Form.Label>
                                                ЛОГОТИП ТУРНИРА
                                            </Form.Label>

                                            <div className='createTournament_download_avatar_container'>
                                                <h3 className='createTournament_download_avatar_title'>Загрузите или выберите аватар</h3>
                                                <div className='createTournament_download_avatar'>
                                                    <Form.Group controlId="formFile" className="create_tournament_form_control date">
                                                        <Form.Control
                                                            ref={fileInputRef}
                                                            type="file"
                                                            name="logoFile"
                                                            accept="image/png, image/jpeg"
                                                            onChange={handleFileChange}
                                                        />
                                                    </Form.Group>
                                                    <div className='createTournament_avatar_container'>
                                                        <div className='createTournament_avatar'>
                                                            <img src={currentAvatar} alt="User Avatar" />
                                                            <button className='createTournament_avatar_button' onClick={rotateRight}>
                                                                <ArrowClockwise className='avatar_button_arrowIcon' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="second">
                                        <Form className="create_tournament_form" noValidate validated={validated}>
                                            <Form.Check
                                                className="create_tournament_checkbox"
                                                type="checkbox"
                                                name="fastCupFlag"
                                                label={(
                                                    <>
                                                        FAST CUP <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popoverFastCup}>
                                                            <InfoCircleFill className='create_tournament_info' />
                                                        </OverlayTrigger>
                                                    </>
                                                )}
                                                checked={tournament.fastCupFlag}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    setTournament(prevTournament => ({
                                                        ...prevTournament,
                                                        fastCupFlag: isChecked,
                                                    }));
                                                }}
                                            />
                                            <FloatingLabel label="МАКСИМАЛЬНОЕ КОЛИЧЕСТВО КОМАНД" className='create_tourn_label'>
                                                <Form.Select
                                                    className="create_tournament_form_control option"
                                                    name="maxSlots"
                                                    onChange={handleInputChange}
                                                    value={tournament.maxSlots}
                                                    required
                                                >
                                                    <option value={8}>8</option>
                                                    <option value={16}>16</option>
                                                    {!tournament.fastCupFlag && (
                                                        <>
                                                            <option value={32}>32</option>
                                                            <option value={64}>64</option>
                                                            <option value={128}>128</option>
                                                        </>
                                                    )}
                                                </Form.Select>
                                            </FloatingLabel>

                                            <Form.Check
                                                className="create_tournament_checkbox"
                                                type="checkbox"
                                                name="isRoundSystem"
                                                checked={isRoundSystemChecked}
                                                disabled={isStandardSystemChecked}
                                                onChange={handleRoundSystemChange}
                                                label="КРУГОВАЯ СИСТЕМА"
                                            />

                                            <Form.Check
                                                className="create_tournament_checkbox"
                                                type="checkbox"
                                                name="isStandardSystem"
                                                checked={isStandardSystemChecked}
                                                disabled={isRoundSystemChecked}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    setIsStandardSystemChecked(isChecked);
                                                    if (isChecked) {
                                                        setIsRoundSystemChecked(false);
                                                        setTournament((prev) => ({
                                                            ...prev,
                                                            tournamentTypeEnum: TournamentTypeEnum.OnlySingle,
                                                            lowerMatchBracketTypeEnum: undefined,
                                                        }));
                                                    } else {
                                                        setTournament((prev) => ({
                                                            ...prev,
                                                            tournamentTypeEnum: TournamentTypeEnum.OnlySingle,
                                                            lowerMatchBracketTypeEnum: undefined,
                                                        }));
                                                        setSelectedTournamentTypeEnumBottom(false);
                                                    }
                                                }}
                                                label="СТАНДАРТНАЯ СИСТЕМА"
                                            />

                                            {isStandardSystemChecked && (
                                                <FloatingLabel label="ТИП ТУРНИРА" className='create_tourn_label'>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="tournamentTypeEnum"
                                                        onChange={(e) => {
                                                            const selectedValue = parseInt(e.target.value);
                                                            setTournament({ ...tournament, tournamentTypeEnum: selectedValue });
                                                            if (selectedValue === TournamentTypeEnum.RobinWithDouble || selectedValue === TournamentTypeEnum.OnlyDouble) {
                                                                setSelectedTournamentTypeEnumBottom(true);
                                                            } else {
                                                                setSelectedTournamentTypeEnumBottom(false);
                                                            }
                                                        }}
                                                        value={tournament.tournamentTypeEnum || ''}
                                                    >
                                                        {isRoundSystemChecked ? (
                                                            <>
                                                                <option disabled value={TournamentTypeEnum.RobinWithSingle}>КРУГОВАЯ + SINGLE ELIMINATION</option>
                                                                <option disabled value={TournamentTypeEnum.RobinWithDouble}>КРУГОВАЯ + DOUBLE ELIMINATION</option>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <option value={TournamentTypeEnum.OnlySingle}>SINGLE ELIMINATION</option>
                                                                <option value={TournamentTypeEnum.OnlyDouble}>DOUBLE ELIMINATION</option>
                                                            </>
                                                        )}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            )}
                                            {(isRoundSystemChecked || isStandardSystemChecked) && (
                                                <FloatingLabel
                                                    label={tournament.tournamentTypeEnum === TournamentTypeEnum.OnlyDouble || tournament.tournamentTypeEnum === TournamentTypeEnum.RobinWithDouble ? 'ФОРМАТ МАТЧЕЙ ВЕРХНЕЙ СЕТКИ' : 'ФОРМАТ МАТЧЕЙ'}
                                                    className='create_tourn_label'>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="upperMatchBracketTypeEnum"
                                                        onChange={handleInputChange}
                                                        value={tournament.upperMatchBracketTypeEnum || ''}
                                                    >
                                                        <option value={MatchBracketTypeEnum.BO1}>BO1</option>
                                                        {tournament.tournamentTypeEnum === TournamentTypeEnum.OnlyRobin && (
                                                            <option value={MatchBracketTypeEnum.BO2}>BO2</option>
                                                        )}
                                                        <option value={MatchBracketTypeEnum.BO3}>BO3</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            )}
                                            {selectedTournamentTypeEnumBottom && (
                                                <FloatingLabel label="ФОРМАТ МАТЧЕЙ НИЖНЕЙ СЕТКИ" className='create_tourn_label'>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="lowerMatchBracketTypeEnum"
                                                        onChange={handleInputChange}
                                                        value={tournament.lowerMatchBracketTypeEnum || ''}
                                                    >
                                                        <option value={MatchBracketTypeEnum.BO1}>BO1</option>
                                                        <option value={MatchBracketTypeEnum.BO3}>BO3</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            )}
                                            {isRoundSystemChecked && (
                                                <FloatingLabel label="КОЛИЧЕСТВО КОМАНД В ГРУППЕ" className='create_tourn_label'>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="countTeamInGroup"
                                                        onChange={handleInputChange}
                                                        value={tournament.countTeamInGroup || ''}
                                                        required
                                                    >
                                                        {groupOptions.map(option => (
                                                            <option key={option} value={option}>{option}</option>
                                                        ))}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            )}
                                            {isStandardSystemChecked && (
                                                <>
                                                    <FloatingLabel label="ФИНАЛЬНАЯ СТАДИЯ" className='create_tourn_label'>
                                                        <Form.Select
                                                            className="create_tournament_form_control option"
                                                            name="finalStageTypeEnum"
                                                            onChange={handleInputChange}
                                                            value={tournament.finalStageTypeEnum || ''}
                                                        >
                                                            <option value={FinalStageTypeEnum.BO1}>BO1</option>
                                                            <option value={FinalStageTypeEnum.BO3}>BO3</option>
                                                            <option value={FinalStageTypeEnum.BO5}>BO5</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    <FloatingLabel label="ФОРМАТ ПРОТИВОСТОЯНИЙ" className='create_tourn_label'>
                                                        <Form.Select
                                                            className="create_tournament_form_control option"
                                                            name="matchesFormatEnum"
                                                            onChange={handleInputChange}
                                                            value={tournament.matchesFormatEnum || ''}
                                                        >
                                                            <option value={MatchesFormatEnum.VS1X1}>1 на 1</option>
                                                            <option value={MatchesFormatEnum.VS2X2}>2 на 2</option>
                                                            <option value={MatchesFormatEnum.VS3X3}>3 на 3</option>
                                                            <option value={MatchesFormatEnum.VS5X5}>5 на 5</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </>
                                            )}
                                            {isStandardSystemChecked && (
                                                <>
                                                    <Form.Check
                                                        className="create_tournament_checkbox"
                                                        type="checkbox"
                                                        name="isThirdPlaceEnabled"
                                                        checked={tournament.isThirdPlaceEnabled}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setTournament(prevTournament => ({
                                                                ...prevTournament,
                                                                isThirdPlaceEnabled: isChecked,
                                                                thirdPlaceTypeEnum: isChecked ? FinalStageTypeEnum.BO1 : null
                                                            }));
                                                        }}
                                                        label="МАТЧ ЗА ТРЕТЬЕ МЕСТО"
                                                        disabled={tournament.tournamentTypeEnum === TournamentTypeEnum.OnlyRobin || tournament.tournamentTypeEnum === TournamentTypeEnum.OnlyDouble || tournament.tournamentTypeEnum === TournamentTypeEnum.RobinWithDouble}
                                                    />
                                                    {tournament.isThirdPlaceEnabled && (
                                                        <FloatingLabel label="ФОРМАТ МАТЧА ЗА 3 МЕСТО" className='create_tourn_label'>
                                                            <Form.Select
                                                                className="create_tournament_form_control option"
                                                                name="thirdPlaceTypeEnum"
                                                                onChange={handleInputChange}
                                                                value={tournament.thirdPlaceTypeEnum || ''}
                                                            >
                                                                <option value={FinalStageTypeEnum.BO1}>BO1</option>
                                                                <option value={FinalStageTypeEnum.BO3}>BO3</option>
                                                                <option value={FinalStageTypeEnum.BO5}>BO5</option>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    )}
                                                </>
                                            )}
                                        </Form>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="third">
                                        <CustomProvider theme="dark" locale={ruRU}>
                                            <Form className="create_tournament_form">
                                                {!tournament.fastCupFlag && (
                                                    <>
                                                        <Form.Label>
                                                            ДАТЫ РЕГИСТРАЦИИ НА ТУРНИР
                                                            <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popover}>
                                                                <InfoCircleFill className='create_tournament_info' />
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                        <DateRangePicker
                                                            className="create_tournament_form_control"
                                                            format="dd/MM/yyyy HH:mm"
                                                            placeholder="Выбор"
                                                            value={dateRange}
                                                            onChange={handleRangeChange}
                                                            onClean={() => handleRangeChange(null)}
                                                            ranges={[]}
                                                            shouldDisableDate={beforeToday()}
                                                        />
                                                    </>
                                                )}
                                                <>
                                                    <Form.Label>
                                                        ИГРОВОЙ ДЕНЬ
                                                    </Form.Label>
                                                    <Form.Group className="time_management_create_tournament_group"  >
                                                        <div className="time_management_create_tournament_group_container time">
                                                            <DatePicker
                                                                className='time-picker'
                                                                format="HH:mm"
                                                                cleanable={false}
                                                                hideHours={(hour) => hour < 0 || hour > 23}
                                                                hideMinutes={minute => minute !== 0}
                                                                value={tournament.startMatchHour !== null ? new Date(0, 0, 0, tournament.startMatchHour) : null}
                                                                onChange={(value) => handleTimeChange(value, 'startMatchHour')}
                                                                ranges={[]}
                                                            />
                                                            <span> - </span>
                                                            <DatePicker
                                                                className='time-picker'
                                                                format="HH:mm"
                                                                cleanable={false}
                                                                hideHours={(hour) => hour < 0 || hour > 23}
                                                                hideMinutes={minute => minute !== 0}
                                                                value={tournament.endMatchHour !== null ? new Date(0, 0, 0, tournament.endMatchHour) : null}
                                                                onChange={(value) => handleTimeChange(value, 'endMatchHour')}
                                                                ranges={[]}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Label className="time_management_create_tournament_group_label">
                                                        КОЛИЧЕСТВО ПАРАЛЛЕЛЬНЫХ МАТЧЕЙ
                                                    </Form.Label>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        name="parallelMatchesCount"
                                                        value={tournament.parallelMatchesCount || ''}
                                                        required
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                    </Form.Select>
                                                    {tournament.isThirdPlaceEnabled &&
                                                        (
                                                            <Form.Check
                                                                className="create_tournament_checkbox"
                                                                type="checkbox"
                                                                name="parallelFinals"
                                                                checked={tournament.parallelFinals}
                                                                onChange={(e) => {
                                                                    const isChecked = e.target.checked;
                                                                    setTournament(prevTournament => ({
                                                                        ...prevTournament,
                                                                        parallelFinals: isChecked
                                                                    }));
                                                                }}
                                                                label="ИГРАТЬ ФИНАЛ И МАТЧ ЗА ТРЕТЬЕ МЕСТО ПАРАЛЛЕЛЬНО"
                                                            />
                                                        )}
                                                    {tournament.parallelMatchesCount && tournament.startMatchHour && tournament.endMatchHour ? (
                                                        <>
                                                            <Form.Label>
                                                                ДАТА СТАРТА МАТЧЕЙ
                                                            </Form.Label>
                                                            <div>
                                                                <DatePicker
                                                                    className="create_tournament_form_control"
                                                                    format="dd/MM/yyyy HH:mm"
                                                                    name="firstMatchDate"
                                                                    value={tournament.firstMatchDate ? new Date(tournament.firstMatchDate) : null}
                                                                    onChange={(value) => handleFirstMatchDateChange(value)}
                                                                    shouldDisableHour={(hour, date) => {
                                                                        const regDateEnd = new Date(tournament.regDateEnd);
                                                                        const selectedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                                                                        const regEndDateOnly = new Date(regDateEnd.getFullYear(), regDateEnd.getMonth(), regDateEnd.getDate());

                                                                        if (selectedDate.getTime() === regEndDateOnly.getTime()) {
                                                                            // Ограничения в день окончания регистрации
                                                                            if (tournament.startMatchHour < tournament.endMatchHour) {
                                                                                // Игровой день в пределах одних суток
                                                                                return hour <= regDateEnd.getHours() || hour < tournament.startMatchHour || hour > tournament.endMatchHour;
                                                                            } else {
                                                                                // Игровой день переходит через полночь
                                                                                return hour <= regDateEnd.getHours() || !(hour >= tournament.startMatchHour || hour < tournament.endMatchHour);
                                                                            }
                                                                        } else {
                                                                            // Обычные ограничения для других дней
                                                                            if (tournament.startMatchHour < tournament.endMatchHour) {
                                                                                return hour < tournament.startMatchHour || hour > tournament.endMatchHour;
                                                                            } else {
                                                                                return !(hour >= tournament.startMatchHour || hour < tournament.endMatchHour);
                                                                            }
                                                                        }
                                                                    }}
                                                                    hideMinutes={(minute) => minute !== 0}
                                                                    shouldDisableMinute={minute => minute !== 0}
                                                                    shouldDisableDate={(date) => {
                                                                        const regDateEnd = tournament.regDateEnd ? new Date(tournament.regDateEnd) : new Date();
                                                                        const regEndDateOnly = new Date(regDateEnd.getFullYear(), regDateEnd.getMonth(), regDateEnd.getDate());
                                                                        const selectedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                                                                        return selectedDate < regEndDateOnly;
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    ) : <></>}
                                                    {!tournament.fastCupFlag && tournament.firstMatchDate && tournament.isThirdPlaceEnabled && tournament.tournamentTypeEnum !== TournamentTypeEnum.OnlyRobin && (
                                                        <>
                                                            <Form.Label>
                                                                ДАТА МАТЧА ЗА ТРЕТЬЕ МЕСТО
                                                            </Form.Label>
                                                            <div>
                                                                <DatePicker
                                                                    className="create_tournament_form_control"
                                                                    format="dd/MM/yyyy HH:mm"
                                                                    placeholder="Выбор"
                                                                    name="thirdPlaceDate"
                                                                    shouldDisableHour={(hour, date) => {
                                                                        if (date.toDateString() === minDateForThirdPlaceMatch?.toDateString()) {
                                                                            // Ограничения на время в день матча за третье место
                                                                            if (tournament.startMatchHour < tournament.endMatchHour) {
                                                                                // В пределах одного дня
                                                                                return hour < minDateForThirdPlaceMatch.getHours() || hour > tournament.endMatchHour;
                                                                            } else {
                                                                                // Переход через полночь
                                                                                return hour < minDateForThirdPlaceMatch.getHours() || !(hour >= tournament.startMatchHour || hour < tournament.endMatchHour);
                                                                            }
                                                                        } else {
                                                                            // Обычные ограничения для других дней
                                                                            if (tournament.startMatchHour < tournament.endMatchHour) {
                                                                                return hour < tournament.startMatchHour || hour > tournament.endMatchHour;
                                                                            } else {
                                                                                return !(hour >= tournament.startMatchHour || hour < tournament.endMatchHour);
                                                                            }
                                                                        }
                                                                    }}
                                                                    hideMinutes={(minute) => minute !== 0}
                                                                    shouldDisableMinute={minute => minute !== 0}
                                                                    ranges={[]}
                                                                    cleanable={false}
                                                                    value={tournament.thirdPlaceDate ? new Date(tournament.thirdPlaceDate) : null}
                                                                    onChange={(value) => handleThirdPlaceDateChange(value)}
                                                                    shouldDisableDate={(date) => {
                                                                        const isBeforeMinDate = minDateForThirdPlaceMatch ? date < minDateForThirdPlaceMatch : true;
                                                                        const isAfterMaxDate = maxDateForThirdPlaceMatch ? date > maxDateForThirdPlaceMatch : false;
                                                                        return isBeforeMinDate || isAfterMaxDate;
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                    {!tournament.fastCupFlag && tournament.firstMatchDate && tournament.tournamentTypeEnum !== TournamentTypeEnum.OnlyRobin && (
                                                        <>
                                                            <Form.Label>
                                                                ДАТА ФИНАЛА
                                                            </Form.Label>
                                                            <div>
                                                                <DatePicker
                                                                    className="create_tournament_form_control"
                                                                    format="dd/MM/yyyy HH:mm"
                                                                    placeholder="Выбор"
                                                                    name="firstPlaceDate"
                                                                    disabled={tournament.parallelFinals ? true : false}
                                                                    hideMinutes={(minute) => minute !== 0}
                                                                    shouldDisableHour={(hour, date) => {
                                                                        const minDateForFinal = new Date(tournament.firstPlaceDate);
                                                                        if (date.toDateString() === minDateForFinal.toDateString()) {
                                                                            if (tournament.startMatchHour < tournament.endMatchHour) {
                                                                                return hour < minDateForFinal.getHours() || hour < tournament.startMatchHour || hour > tournament.endMatchHour;
                                                                            } else {
                                                                                return hour < minDateForFinal.getHours() || !(hour >= tournament.startMatchHour || hour < tournament.endMatchHour);
                                                                            }
                                                                        } else {
                                                                            if (tournament.startMatchHour < tournament.endMatchHour) {
                                                                                return hour < tournament.startMatchHour || hour > tournament.endMatchHour;
                                                                            } else {
                                                                                return !(hour >= tournament.startMatchHour || hour < tournament.endMatchHour);
                                                                            }
                                                                        }
                                                                    }}
                                                                    ranges={[]}
                                                                    cleanable={false}
                                                                    value={tournament.firstPlaceDate ? new Date(tournament.firstPlaceDate) : null}
                                                                    onChange={(value) => handleFirstPlaceDateChange(value)}
                                                                    shouldDisableDate={(date) => {
                                                                        const minFinalDate = new Date(tournament.firstPlaceDate);
                                                                        const isBeforeMinDate = minFinalDate ? date < minFinalDate : false;
                                                                        const isAfterMaxDate = maxDateForThirdPlaceMatch ? date > new Date(maxDateForThirdPlaceMatch) : false;
                                                                        return isBeforeMinDate || isAfterMaxDate;
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            </Form>
                                        </CustomProvider>
                                    </Tab.Pane>

                                    {tournament.gameTypeId === TournamentConstants.CS2.gameTypeId && (
                                        <CS2TabCreateTournament
                                            cs2Tournament={cs2Tournament}
                                            handleCS2TournamentVMChange={handleCS2TournamentVMChange}
                                            showCompetitive={showCompetitive}
                                            handleCheckboxChange={handleCheckboxChange}
                                            filteredMaps={filteredMaps}
                                            selectedMaps={selectedMaps}
                                            handleMapClick={handleMapClick}
                                        />
                                    )}
                                    {tournament.gameTypeId === TournamentConstants.DOTA2.gameTypeId && (
                                        <Dota2TabCreateTournament
                                            dota2Tournament={dota2Tournament}
                                            handleDota2TournamentVMChange={handleDota2TournamentVMChange} />
                                    )}

                                    <Tab.Pane eventKey="fifth">
                                        <Form className="create_tournament_form">
                                            <FloatingLabel label="ДОПОЛНИТЕЛЬНЫЕ СЛОТЫ" className='create_tourn_label'>
                                                <Form.Select
                                                    className="create_tournament_form_control option"
                                                    name="additionalSlots"
                                                    value={tournament.additionalSlots || ''}
                                                    required
                                                    onChange={handleInputChange}
                                                >
                                                    <option value={-1}>0</option>
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                    <option value={9}>9</option>
                                                    <option value={10}>10</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                            <Form.Check
                                                className="create_tournament_checkbox"
                                                type="checkbox"
                                                name="lobbyStaffWaiting"
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    setTournament(prevTournament => ({
                                                        ...prevTournament,
                                                        lobbyStaffWaiting: isChecked
                                                    }));
                                                }}
                                                label="Ожидать готовность персонала в игровом лобби"
                                            />
                                            <Form.Check
                                                className="create_tournament_checkbox"
                                                type="checkbox"
                                                name="coachesAllowedInMatches"
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    setTournament(prevTournament => ({
                                                        ...prevTournament,
                                                        coachesAllowedInMatches: isChecked
                                                    }));
                                                }}
                                                label="Разрешить присутствие тренера в матче"
                                            />
                                            {tournament.additionalSlots && tournament.additionalSlots > 0 && (
                                                <>
                                                    <div className='added_membersContainer'>
                                                        {additionalMembers.map((member) => (
                                                            <div key={member.playerId} className='teammates__teamcreate_unit'>
                                                                <X className='team_member_unit_name_x' onClick={() => handleShowMyAlertForMember(member.playerId || '')} />
                                                                <img className='teammates__teamcreate_avatar' src={`${process.env.REACT_APP_IMG_MINIO_URL}${member.avatarUrl}`} alt="" />
                                                                <div className='teammates__teamcreate_name' onClick={() => { window.open(`/player/${member.playerId}`, '_blank') }}>{member.playerName}</div>

                                                                <Form.Select
                                                                    className="create_tournament_form_control option"
                                                                    name="memberRole"
                                                                    onChange={e => { addMemberByRole(e.target.value, member.playerId || '') }}
                                                                >
                                                                    <option value="">Роль</option>
                                                                    <option value="Admin">Админ</option>
                                                                    <option value="Streamer">Стример</option>
                                                                    <option value="Observer">Обсервер</option>
                                                                </Form.Select>

                                                                <MyAlert
                                                                    onHide={handleCloseMyAlert}
                                                                    show={showMyAlert}
                                                                    alertText='Вы точно хотите удалить пользователя?'
                                                                    buttons={[
                                                                        {
                                                                            text: 'Да',
                                                                            onClick: () => {
                                                                                removeSelectedMember({
                                                                                    playerId: selectedPlayerId,
                                                                                    playerName: '',
                                                                                    avatarUrl: ''
                                                                                });
                                                                                handleCloseMyAlert();
                                                                            }
                                                                        },
                                                                        { text: 'Нет', onClick: handleCloseMyAlert }
                                                                    ]}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <div className='create_last_form_control'>
                                                        <Form.Label>
                                                            ДОБАВЛЕНИЕ ПЕРСОНАЛА {additionalMembers.length}/{tournament.additionalSlots}
                                                            <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popoverPersonal}>
                                                                <InfoCircleFill className='create_tournament_info' />
                                                            </OverlayTrigger>
                                                        </Form.Label>

                                                        <div className='search_team_top'>
                                                            <Form className="search_team_bar">
                                                                <Form.Control
                                                                    name="search"
                                                                    autoComplete="off"
                                                                    placeholder="ВВЕДИТЕ НИКНЕЙМ"
                                                                    onChange={handleInputSearch}
                                                                    onKeyDown={handleKeyDown}
                                                                    className="rounded-pill"
                                                                />
                                                            </Form>

                                                            <div className='search_team_topButton'>
                                                                <button type="submit" className='submit_team_button' onClick={handleSearchSubmit}><Search /></button>
                                                            </div>
                                                        </div>

                                                        {searchResults !== null && searchResults.length > 0 && (
                                                            <div className="players_selecor_block">
                                                                {searchResults?.map((result) => (
                                                                    <div className="searched_players_nickname" key={result.playerId} >
                                                                        <div className='searched_players_nickname_logo'>
                                                                            <img className='searched_players_nickname_logo' src={`${process.env.REACT_APP_IMG_MINIO_URL}${result.avatarUrl}`} alt="" />
                                                                        </div>
                                                                        <div className='searched_players_nickname_playername' onClick={() => { window.open(`/player/${result.playerId}`, '_blank') }}>
                                                                            {result.playerName}
                                                                        </div>
                                                                        <PlusCircle className='searched_players_nickname_plus' onClick={() => addAdditionalMembers(result)} size={27} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </Container >
    );
});

export default CreateTournament;